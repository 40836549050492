import React, { useCallback } from "react";
import DinosaurCard from "../DinosaurCard";
import { Icon } from "@iconify/react";
import {
  dinosaurGrowths,
  dinosaurSizes,
  dinosaurTypes,
  megaShopTypes,
} from "../../utils/consts";
import ToBasket from "../ToBasket";
import { v4 as uuidv4 } from "uuid";
import basketStore from "../../store/basketStore";
import { catalogCategories } from "../../utils/megaShopItemsData";

const BasketItem = (props) => {
  const id = +props.id;
  const isConfirm = props.isConfirm;
  const item = props.item;
  const confirmDeletion = props.confirmDeletion || (() => {});

  return (
    <div key={uuidv4()} className="info-card basket-item">
      {!isConfirm && (
        <div className="checkbox d-flex align-center">
          <input
            type="checkbox"
            id={id}
            checked={basketStore.checkedItemsIds.has(id)}
            onChange={() => basketStore.toggleCheckedItem(id)}
          />
          <label htmlFor={id} />
        </div>
      )}
      <div className="basket-item-image">
        <DinosaurCard
          data={{
            image_url: item.image_url,
            type: dinosaurTypes[item.category],
            tier: item.tier,
            name_ru: item.name_ru,
          }}
          size={dinosaurSizes.small}
        />
        {!isConfirm && (
          <button onClick={() => confirmDeletion(new Set([id]))}>
            <Icon icon="iconamoon:trash" width="1.2rem" height="1.2rem" />
          </button>
        )}
      </div>
      <div className="info">
        {item.name_ru && (
          <p className="basket-item-name bold">{item.name_ru}</p>
        )}
        {item.category && (
          <p className="basket-item-type">Тип: {item.category}</p>
        )}
        {item.tier && <p className="basket-item-tier">Тир: {item.tier}</p>}
        {item.growth && (
          <p className="basket-item-description">
            Рост: {dinosaurGrowths[item.growth]}
          </p>
        )}
        {item.server && (
          <p className="basket-item-description">Сервер: {item.server}</p>
        )}
      </div>
      {!isConfirm && (
        <div className="actions d-flex justify-between">
          <ToBasket data={item} />
        </div>
      )}
    </div>
  );
};

export default BasketItem;
