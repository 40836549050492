import React, { useState } from "react";
import Button from "../components/Button";
import { ReactComponent as DoneSvg } from "../assets/svg/done.svg";
import { ReactComponent as ArrowSvg } from "../assets/svg/arrow_down.svg";
import { observer } from "mobx-react-lite";
import Dropdown from "../components/Dropdown";
import {
  notificationTimeouts,
  notificationTypes,
  paymentSystems,
  paymentSystemsHtml,
  paymentSystemsImages,
  paymentSystemsNames,
  paymentTypes,
  paymentTypesImages,
  paymentTypesNames,
  valutes,
} from "../utils/consts";
import userStore from "../store/userStore";
import { showStandartNotification } from "../utils/helpers";
import "../css/add-balance.css";

const AddBalance = (props) => {
  const [addBalanceSum, setAddBalanceSum] = useState("");
  const [choosedPaymentType, setChoosedPaymentType] = useState(
    paymentTypesNames.yookassa
  );

  async function handlePayment() {
    if (!addBalanceSum || isNaN(addBalanceSum) || addBalanceSum <= 0) {
      return showStandartNotification({
        text: "Введите корректную сумму",
        timeout: notificationTimeouts.short,
        type: notificationTypes.error,
      });
    }

    try {
      // const response = await fetch("/api/create-payment", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ amount: addBalanceSum }),
      // });

      // const data = await response.json();

      // if (data.success && data.paymentUrl) {
      //   window.location.href = data.paymentUrl; // Перенаправление на страницу оплаты
      // } else {
      //   showStandartNotification({
      //     text: "Ошибка при создании платежа",
      //     timeout: notificationTimeouts.short,
      //     type: notificationTypes.error,
      //   });
      // }
      console.log("В разработке");
      // userStore.addUserValutes({ [valutes.teeth]: +addBalanceSum });
      // showStandartNotification({
      //   text: "Успешно",
      //   timeout: notificationTimeouts.short,
      //   type: notificationTypes.done,
      // });
    } catch (err) {
      showStandartNotification({
        text: "Ошибка",
        timeout: notificationTimeouts.short,
        type: notificationTypes.error,
      });
      console.log(err);
    }
  }

  return (
    <div className="balance-container">
      <div className="payment-type">
        <span className="choosed-payment-type float-right">
          Выбрано: {paymentTypesImages[choosedPaymentType]} {choosedPaymentType}
        </span>
        <Dropdown
          noArrow={true}
          value={
            <Button className="choose-button gradient-background extra no-border spiked gap-0-25">
              выберите способ пополнения <ArrowSvg className="arrow" />
            </Button>
          }
          up={props.up ? true : false}
          animated={true}
        >
          {Object.values(paymentTypesNames).map((name) => (
            <button>
              {paymentTypesImages[name]} {name}
            </button>
          ))}
        </Dropdown>
      </div>
      <div className="sum">
        <input
          type="number"
          className="text-center"
          placeholder="сумма"
          min={0}
          value={addBalanceSum}
          onChange={(e) => setAddBalanceSum(parseInt(e.target.value))}
        />
        <Button className="no-border spiked gap-0-25" onClick={handlePayment}>
          <DoneSvg /> ПОПОЛНИТЬ
        </Button>
      </div>
    </div>
  );
};

export default observer(AddBalance);
