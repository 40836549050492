import React, { useState, useEffect } from "react";
import "../css/scroll-to-top.css";
import Button from "./Button";
import { Icon } from "@iconify/react";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  let timeoutId = null;

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsVisible(false);
      clearTimeout(timeoutId);
      timeoutId = null;
    } else if (!timeoutId) {
      timeoutId = setTimeout(() => {
        setIsVisible(true);
      }, 1000);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <Button className="scroll-to-top" onClick={handleScrollToTop}>
        <Icon
          icon="simple-line-icons:arrow-up"
          width="1.2rem"
          height="1.2rem"
        />
      </Button>
    )
  );
};

export default ScrollToTopButton;
