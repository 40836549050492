import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "../css/double-range.css";

const DoubleRange = ({ min, max, onChange, step, minValue, maxValue }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const [minInput, setMinInput] = useState(min);
  const [maxInput, setMaxInput] = useState(max);
  const minValRef = useRef(minVal);
  const maxValRef = useRef(maxVal);
  const range = useRef(null);
  const trackRef = useRef(null);
  const isExternalUpdate = useRef(false); // Tracks external updates

  // Update on parent value changes
  useEffect(() => {
    if (minValue !== undefined && maxValue !== undefined) {
      isExternalUpdate.current = true; // Mark as external update
      setMinInput(minValue);
      setMaxInput(maxValue);
      setMinVal(minValue);
      setMaxVal(maxValue);
      minValRef.current = minValue;
      maxValRef.current = maxValue;
    }
  }, [minValue, maxValue]);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    if (!isExternalUpdate.current) {
      onChange({ min: minVal, max: maxVal });
    } else {
      isExternalUpdate.current = false; // Reset external update flag
    }
  }, [minVal, maxVal, onChange]);

  const handleMinInputChange = (event) => {
    const value = event.target.value.replace(/^0+(?!$)/, ""); // Remove leading zeros
    setMinInput(value);
    const numericValue = Number(value);
    if (numericValue >= min && numericValue <= maxVal - 1) {
      setMinVal(numericValue);
      minValRef.current = numericValue;
    }
  };

  const handleMaxInputChange = (event) => {
    const value = event.target.value.replace(/^0+(?!$)/, ""); // Remove leading zeros
    setMaxInput(value);
    const numericValue = Number(value);
    if (numericValue <= max && numericValue >= minVal + 1) {
      setMaxVal(numericValue);
      maxValRef.current = numericValue;
    }
  };

  const handleKeyDown = (event, onBlurHandler) => {
    if (event.key === "Enter") {
      onBlurHandler();
    }
  };

  const handleRangeClick = (event) => {
    const rect = trackRef.current.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickPercent = (clickX / rect.width) * 100;
    const clickValue = Math.round(min + ((max - min) * clickPercent) / 100);

    const minDiff = Math.abs(clickValue - minVal);
    const maxDiff = Math.abs(clickValue - maxVal);

    if (minDiff < maxDiff && clickValue <= maxVal - 1) {
      setMinVal(clickValue);
      setMinInput(Math.round(clickValue));
      minValRef.current = Math.round(clickValue);
    } else if (clickValue >= minVal + 1) {
      setMaxVal(clickValue);
      setMaxInput(Math.round(clickValue));
      maxValRef.current = Math.round(clickValue);
    }
  };

  return (
    <div className="double-range">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          setMinInput(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          setMaxInput(value);
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div
          ref={trackRef}
          className="slider__track"
          onClick={handleRangeClick}
        />
        <div ref={range} className="slider__range" onClick={handleRangeClick} />
        <input
          value={minInput}
          type="number"
          className="slider__left-value"
          onChange={handleMinInputChange}
          onBlur={() => setMinInput(minVal)} // Reset if invalid
          onKeyDown={(event) => handleKeyDown(event, () => setMinInput(minVal))}
        />
        <input
          value={maxInput}
          type="number"
          className="slider__right-value"
          onChange={handleMaxInputChange}
          onBlur={() => setMaxInput(maxVal)} // Reset if invalid
          onKeyDown={(event) => handleKeyDown(event, () => setMaxInput(maxVal))}
        />
      </div>
    </div>
  );
};

DoubleRange.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  step: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

export default DoubleRange;
