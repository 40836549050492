import { makeAutoObservable } from "mobx";
import { initialFilters } from "../utils/consts";

class MegaShopStore {
  data = [];
  visibleData = [];
  filters = {
    type: new Set(),
    tier: new Set(),
    weight: new Map([
      ["minWeight", 50],
      ["maxWeight", 50000],
    ]),
    search: "",
    growth: new Set(),
  };

  constructor() {
    makeAutoObservable(this);
  }

  setData(data) {
    this.data = data;
  }

  setVisibleData(data) {
    this.visibleData = data;
  }

  setFilters(filters) {
    this.filters = filters;
  }
}

export const megaShopStore = new MegaShopStore();
