class MotionProps {
  get leftWithOpacity() {
    return {
      initial: {
        x: -100,
        opacity: 0,
      },
      whileInView: {
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          type: "tween",
        },
      },
      viewport: {
        once: true,
        amount: 0.2,
      },
    };
  }

  rightWithOpacity = {
    initial: {
      x: 100,
      opacity: 0,
    },
    whileInView: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
    viewport: {
      once: true,
      amount: 0.2,
    },
  };

  topWithOpacity = {
    initial: {
      y: -100,
      opacity: 0,
    },
    whileInView: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
    viewport: {
      once: true,
      amount: 0.2,
    },
  };

  bottomWithOpacity = {
    initial: {
      y: 100,
      opacity: 0,
    },
    whileInView: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
    viewport: {
      once: true,
      amount: 0.2,
    },
  };
}

export const motionProps = new MotionProps();
