const { useEffect } = require("react");

export const useSmoothScroll = (
  step = 200,
  speed = 500,
  easing = "easeOutQuad"
) => {
  useEffect(() => {
    let scrollRoot = 0;
    let isScrolling = false;

    const handleScroll = (e) => {
      const delta = e.deltaY || e.detail || -e.wheelDelta;
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;

      if (documentHeight > windowHeight) {
        isScrolling = true;
        scrollRoot = window.scrollY;

        if (delta > 0) {
          scrollRoot = Math.min(
            scrollRoot + step,
            documentHeight - windowHeight
          );
        } else {
          scrollRoot = Math.max(scrollRoot - step, 0);
        }

        window.scrollTo({
          top: scrollRoot,
          behavior: "smooth",
        });

        setTimeout(() => {
          isScrolling = false;
        }, speed);
      }

      e.preventDefault();
    };

    const handleResize = () => {
      if (!isScrolling) {
        scrollRoot = window.scrollY;
      }
    };

    window.addEventListener("wheel", handleScroll, { passive: false });
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [step, speed, easing]);
};
