import { useContext, useEffect, useMemo } from "react";
import { ReactComponent as DoorSvg } from "../assets/svg/possibility/door.svg";
import { ReactComponent as FinishFlagSvg } from "../assets/svg/possibility/finish-flag.svg";
import { ReactComponent as FlagSvg } from "../assets/svg/possibility/flag.svg";
import { ReactComponent as GridSvg } from "../assets/svg/possibility/grid.svg";
import { ReactComponent as HeadphonesSvg } from "../assets/svg/possibility/headphones.svg";
import { ReactComponent as PeopleSvg } from "../assets/svg/possibility/people.svg";
import { ReactComponent as StarSvg } from "../assets/svg/possibility/star.svg";
import { ReactComponent as StarsSvg } from "../assets/svg/possibility/stars.svg";
import { ReactComponent as TimeSvg } from "../assets/svg/possibility/time.svg";

import Button from "../components/Button";
import ComponentHeading, {
  MComponentHeading,
} from "../components/Headings/ComponentHeading";
import MiniHeading from "../components/Headings/MiniHeading";
import { MPossibilityCard } from "../components/PossibilityCard";
import ProgressBar, { MProgressBar } from "../components/ProgressBar";
import {
  dinosaurSizes,
  dinosaurTypes,
  pageAdditions,
  pageNames,
  serverUrls,
  wpImagePath,
} from "../utils/consts";

import { Context } from "..";
import HelperHeading from "../components/Headings/HelperHeading";
import NumerableList from "../components/NumerableList";
import "../css/home.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { independentPaths, paths } from "../paths";
import { usePageName } from "../hooks/usePageName";
import BubblyButton from "../components/Buttons/BubblyButton";
import GlowingButton from "../components/Buttons/GlowingButton";
import NeonButton, { MNeonButton } from "../components/Buttons/NeonButton";
import VioletButton from "../components/Buttons/VioletButton";
import ComponentContainer from "../components/ComponentContainer";
import userStore from "../store/userStore";
import { AnimatePresence } from "motion/react";
import { motion } from "framer-motion";
import RulesEvrima from "./Rules/RulesEvrima";
import Rules from "./Rules/Rules";
import { observer } from "mobx-react-lite";
import SteamAuthButton from "../components/Buttons/ActionButtons/SteamAuthButton";
import { steamCallback } from "../api/auth/steam/steamAuth";

const Home = () => {
  usePageName(pageNames.Home, pageAdditions.Home);
  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const returnUrl = userStore.choosedServerUrl;

  console.log(location, Object.fromEntries(urlParams.entries()));

  // Авторизация в стим
  useEffect(() => {
    (async () => {
      if (urlParams.get("openid.claimed_id")) {
        const data = await steamCallback(
          Object.fromEntries(urlParams.entries())
        );

        console.log(data);

        if (data) {
          userStore.setIsAuth(true);
          userStore.setData(data);
        }
        navigate(
          userStore.choosedServerUrl
          //    {
          //   replace: true,
          // }
        );
      }
    })();
  }, [location.pathname, navigate, urlParams]);

  const viewportValue = {
    once: true,
    amount: 0.3,
  };
  const initialValue = { y: 100 };
  const whileInViewValue = {
    y: 0,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  };
  const serverBarsAnimations = {
    hidden: { opacity: 0, y: 100 },
    visible: (custom = 0) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.3,
      },
    }),
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        className="home-page"
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={viewportValue}
          className="inside-container component-container"
        >
          <div className="progress-bars mb-2">
            <div className="progress-bar-content">
              <motion.p
                variants={serverBarsAnimations}
                custom={1}
                className="sub-heading"
              >
                CREATIVE
              </motion.p>
              <motion.div variants={serverBarsAnimations} custom={2}>
                <MProgressBar
                  isAnimated={true}
                  value={0}
                  max={100}
                  manyColors={true}
                />
              </motion.div>
              <motion.div variants={serverBarsAnimations} custom={3}>
                <MNeonButton
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="no-background spiked extended"
                >
                  НАЧАТЬ ИГРАТЬ
                </MNeonButton>
              </motion.div>
            </div>
            <div className="progress-bar-content">
              <motion.p
                variants={serverBarsAnimations}
                custom={1}
                className="sub-heading"
              >
                DOMINATION
              </motion.p>
              <motion.div variants={serverBarsAnimations} custom={2}>
                <MProgressBar
                  isAnimated={true}
                  value={125}
                  max={230}
                  manyColors={true}
                />
              </motion.div>
              <motion.div variants={serverBarsAnimations} custom={3}>
                <MNeonButton
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="no-background spiked extended"
                >
                  НАЧАТЬ ИГРАТЬ
                </MNeonButton>
              </motion.div>
            </div>
            <div className="progress-bar-content">
              <motion.p
                variants={serverBarsAnimations}
                custom={1}
                className="sub-heading"
              >
                HARBOR
              </motion.p>
              <motion.div variants={serverBarsAnimations} custom={2}>
                <MProgressBar
                  isAnimated={true}
                  value={197}
                  max={230}
                  manyColors={true}
                />
              </motion.div>
              <motion.div variants={serverBarsAnimations} custom={3}>
                <MNeonButton
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="no-background spiked extended"
                >
                  НАЧАТЬ ИГРАТЬ
                </MNeonButton>
              </motion.div>
            </div>
            <div className="progress-bar-content">
              <motion.p
                variants={serverBarsAnimations}
                custom={1}
                className="sub-heading"
              >
                LEGACY
              </motion.p>
              <motion.div variants={serverBarsAnimations} custom={2}>
                <MProgressBar
                  isAnimated={true}
                  value={36}
                  max={50}
                  manyColors={true}
                />
              </motion.div>
              <motion.div variants={serverBarsAnimations} custom={3}>
                <MNeonButton
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="no-background spiked extended"
                >
                  НАЧАТЬ ИГРАТЬ
                </MNeonButton>
              </motion.div>
            </div>
          </div>
          {!userStore.isAuth && (
            <motion.div
              variants={serverBarsAnimations}
              custom={4}
              className="center mb-lg"
            >
              <SteamAuthButton />
            </motion.div>
          )}
        </motion.div>
        <ComponentContainer className="mb-lg">
          <MComponentHeading>Возможности сервера и сайта</MComponentHeading>
          <div className="flex-container posibility-cards">
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<PeopleSvg />}
              title="Многопользовательская игра"
              text="Сервер позволяет играть с игроками со всего мира и исследовать мир динозавров в команде."
            />
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<FlagSvg />}
              title="Захватывающие задания и квесты"
              text="Сервер предлагает интересные квесты для всех игроков."
            />
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<StarSvg />}
              title="Разнообразие динозавров"
              text="Сервер предлагает разнообразие динозавров с уникальными способностями для выбора под стиль игры."
            />
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<StarsSvg />}
              title="Развитие и улучшение"
              text="Сервер предлагает развитие динозавра с улучшением навыков и новыми способностями для эффективных битв."
            />
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<DoorSvg />}
              title="Создание гильдий"
              text="Гильдия позволяет создать сообщество друзей и единомышленников для комфортного общения."
            />
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<FinishFlagSvg />}
              title="Онлайн соревнования"
              text="Сервер проводит турниры, где игроки могут соревноваться и зарабатывать призы."
            />
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<GridSvg />}
              title="Система слотов"
              text="Система слотов позволяет хранить несколько динозавров на одном аккаунте, активируя и деактивируя слоты."
            />
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<TimeSvg />}
              title="Постоянные обновления и нововведения"
              text="Администрация сервера регулярно обновляет игру, добавляя новые функции и возможности для игроков."
            />
            <MPossibilityCard
              initial={initialValue}
              whileInView={whileInViewValue}
              viewport={viewportValue}
              image={<HeadphonesSvg />}
              title="Поддержка и помощь"
              text="Сервер предоставляет поддержку игрокам. Обращайтесь к администрации с вопросами и получайте помощь."
            />
          </div>
        </ComponentContainer>
        {/* <ComponentContainer>
          <div className="short-rules-container">
            <div className="rules back-image">
              <div className="heading-container">
                <MComponentHeading
                  initial={{
                    y: 100,
                    opacity: 0,
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                      type: "tween",
                    },
                  }}
                  viewport={{
                    once: true,
                    amount: 0.2,
                  }}
                >
                  ПРАВИЛА СЕРВЕРА
                </MComponentHeading>
                <MiniHeading>рекомендовано к ознакомлению</MiniHeading>
              </div>
              {userStore.choosedServerUrl === serverUrls.evrima && (
                <RulesEvrima isShort={true} />
              )}
              {userStore.choosedServerUrl === serverUrls.legacy && (
                <Rules isShort={true} />
              )}
            </div>
            <div className="center">
              <Link
                to={
                  userStore.choosedServerUrl === serverUrls.evrima
                    ? independentPaths.RulesEvrima
                    : independentPaths.Rules
                }
              >
                <Button className="accent no-border">
                  ПОЛНЫЙ СПИСОК ПРАВИЛ
                </Button>
              </Link>
            </div>
          </div>
        </ComponentContainer> */}
      </motion.div>
    </AnimatePresence>
  );
};

export default observer(Home);
