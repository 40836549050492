import React from "react";
import StandartNotification from "../components/Notifications/StandartNotification";
import {
  contextValue,
  initialCookieOptions,
  notificationTypes,
  scrollbarWidth,
} from "./consts";
import ReactDOM from "react-dom/client";
import Modal from "../components/Modal";
import Offcanvas from "../components/Offcanvas";
import basketStore from "../store/basketStore";
import { Context } from ".."; // для store mobx
import { BrowserRouter, useNavigate } from "react-router-dom"; // для Link

class Helpers {
  navigate = useNavigate();

  navigateTo(to, options = {}) {
    console.log("fourth", "last");
    this.navigate(to, options);
  }
}
export const toggleActive = (event) => {
  //event.stopPropagation();
  const target = event.target;
  if (target.classList.contains("active-handler")) {
    target.classList.toggle("active");
  } else {
    target.closest(".active-handler")?.classList.toggle("active");
  }
};

export const showStandartNotification = (props) => {
  const { text, timeout = null, type = notificationTypes.classic } = props;
  const notificationContainer = document.querySelector(
    "#notification-container"
  );
  const div = document.createElement("div");
  const notificationElement = (
    <Context.Provider value={contextValue}>
      <StandartNotification
        onClose={onClose}
        {...props}
        text={text}
        type={type}
      />
    </Context.Provider>
  );

  notificationContainer.append(div);
  const root = ReactDOM.createRoot(div);
  root.render(notificationElement);

  function onClose() {
    root.unmount();
    div.remove();
  }

  if (timeout) {
    setTimeout(() => {
      div.classList.add("fade");
    }, timeout - 2000);
    setTimeout(onClose, timeout);
  }

  return onClose;
};

// export const showModal = (props) => {
//   const modalContainer = document.querySelector("#modal-container");
//   const div = document.createElement("div");
//   const modalElement = (
//     <Context.Provider value={contextValue}>
//       <BrowserRouter>
//         <Modal {...props} onClose={onClose} onLink={onLink} />
//       </BrowserRouter>
//     </Context.Provider>
//   );

//   modalContainer.append(div);
//   const root = ReactDOM.createRoot(div);
//   root.render(modalElement);

//   document.body.style.paddingRight = `${scrollbarWidth}px`;

//   function onClose() {
//     root.unmount();
//     div.remove();
//     if (props.onClose) {
//       props.onClose();
//     }
//     document.body.style.paddingRight = ``;
//   }

//   function onLink(link, options = {}) {
//     console.log("third");
//     console.log(link, options);
//     onClose();
//     if (link) {
//       console.log("empty for while");
//     }
//   }

//   return { onClose, onLink };
// };

export const showOffcanvas = (props) => {
  const offcanvasContainer = document.querySelector("#offcanvas-container");
  const div = document.createElement("div");
  const offcanvasElement = (
    <Context.Provider value={contextValue}>
      <Offcanvas {...props} onClose={onClose} />
    </Context.Provider>
  );
  offcanvasContainer.append(div);
  const root = ReactDOM.createRoot(div);
  root.render(offcanvasElement);

  function onClose() {
    root.unmount();
    div.remove();
    if (props.onClose) {
      props.onClose();
    }
  }

  return onClose;
};

let timeoutId;
export const debounce = (func, delay = 500) => {
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

//                                           [1, 4, 5]
export function getGrammaticalEnding(number, wordsArr) {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;
    let options = [2, 0, 1, 1, 1, 2];
    return wordsArr[
      lastTwoDigits > 4 && lastTwoDigits < 20
        ? 2
        : options[lastDigit < 5 ? lastDigit : 5]
    ];
  }
  return wordsArr[1];
}

export const disableScroll = () => {
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;
  document.body.style.overflow = "hidden";
  document.body.style.paddingRight = `${scrollbarWidth}px`;
};

export const enableScroll = () => {
  document.body.style.overflow = "";
  document.body.style.paddingRight = "";
};
