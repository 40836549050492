import React from "react";
import ComponentContainer from "../components/ComponentContainer";
import SmallComponentHeading from "../components/Headings/SmallComponentHeading";
import { Link, useNavigate } from "react-router-dom";
import MyLink from "../components/MyLink";
import { paths } from "../paths";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="page not-found-page">
      <ComponentContainer>
        <div className="not-found-page-container d-flex justify-center direction-column align-items-center">
          <SmallComponentHeading>Ничего не найдено :(</SmallComponentHeading>
          <div className="">
            <p className="mb-1">
              Возможно, страница была удалена или перемещена.
            </p>
            <div className="d-flex justify-between">
              <button className="text-accent" onClick={() => navigate(-1)}>
                Вернуться назад
              </button>
              <MyLink to={paths.Home}>На главную</MyLink>
            </div>
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default NotFound;
