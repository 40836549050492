import React from "react";
import "../css/indicator.css";

const Indicator = (props) => {
  return (
    <div className="position-relative">
      <span
        {...props}
        className={`indicator ${props.className ? props.className : ""}`}
      >
        {props.children}
      </span>
    </div>
  );
};

export default Indicator;
