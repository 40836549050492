import React, { forwardRef } from "react";
import "../../css/component-heading.css";
import { motion } from "framer-motion";

const ComponentHeading = forwardRef((props, ref) => {
  return (
    <p
      ref={ref}
      {...props}
      className={`main-heading ${props.className ? props.className : ""}`}
    >
      {props.children}
    </p>
  );
});

export default ComponentHeading;
export const MComponentHeading = motion.create(ComponentHeading);
