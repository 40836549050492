import { makeAutoObservable } from "mobx";
import { Cookies } from "react-cookie";
import { cookieNames, initialCookieOptions } from "../utils/consts";

const cookies = new Cookies();
class UserStore {
  isAuth = false;
  data = {};
  pageName = {
    name: "",
    addition: "",
  };
  choosedServerUrl = "";
  storage = [];
  userValutes = Object.freeze({
    oa: 0,
    teeth: 0,
  });

  constructor() {
    //makeAutoObservable(this);
    makeAutoObservable(
      this
      //   {
      //   setIsAuth: action,
      //   setData: action,
      //   setUserValutes: action,
      //   addUserValutes: action,
      //   substractUserValutes: action,
      //   setPageName: action,
      //   setChoosedServerUrl: action,
      //   addToStorage: action,
      //   pushStorage: action,
      //   deleteStorageItems: action,
      // }
    );
  }

  setIsAuth(bool) {
    this.isAuth = bool;
  }
  setData(data) {
    this.data = data;
  }

  setUserValutes(valutes) {
    this.userValutes = valutes;
  }

  addUserValutes(valutesObj) {
    Object.entries(valutesObj).forEach(([valute, value]) => {
      if (this.userValutes[valute] !== undefined) {
        this.userValutes[valute] += +value;
      }
    });
  }

  substractUserValutes(valutesObj) {
    Object.entries(valutesObj).forEach(([valute, value]) => {
      if (this.userValutes[valute] !== undefined) {
        this.userValutes[valute] -= +value;
      }
    });
  }

  setPageName(name, addition = "") {
    this.pageName = { name, addition };
  }

  setChoosedServerUrl(url) {
    this.choosedServerUrl = url;
  }

  setStorage(newStorage) {
    this.storage = newStorage;
    const oldStorage = cookies.get(cookieNames.storage);
    console.log(oldStorage, newStorage);
    if (oldStorage) {
      newStorage.forEach((newItem) => {
        const existingItem = oldStorage.find((item) => item.id === newItem.id);

        if (existingItem) {
          // Если объект с таким id найден, увеличиваем amount
          existingItem.amount += newStorage.amount;
        } else {
          // Если объекта нет, добавляем новый
          newStorage.push(newItem);
        }
      });
    }
    cookies.set(cookieNames.storage, newStorage, initialCookieOptions);
  }

  async addToStorage(items) {
    const newStorage = [...this.storage];
    newStorage.push(...items);
    this.setStorage(newStorage);
  }

  deleteStorageItems(itemsIds) {
    let newStorage = [...this.storage];
    if (itemsIds instanceof Array) {
      newStorage = this.storage.filter((item) => !itemsIds.includes(item.id));
    } else if (itemsIds instanceof Set) {
      newStorage = this.storage.filter((item) => !itemsIds.has(item.id));
    }
    this.setStorage(newStorage);
  }
}

const userStore = new UserStore();

export default userStore;
