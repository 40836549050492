import React, { forwardRef } from "react";
import "../css/modal.css";
import { motion } from "framer-motion";

const Modal = forwardRef((props, ref) => {
  const onClose = props.onClose || (() => {});
  const onLink = props.onLink || (() => {});

  function closeModal() {
    onClose();
  }

  function handleOnLink(link) {
    onLink();
  }

  return (
    <div ref={ref} className={`modal visible`}>
      <div className="modal-fixed">
        <div className="shade" onClick={closeModal} />
        <div className="modal-container">
          <button className="close" onClick={closeModal} />
          <div className="modal-children">
            {React.Children.map(props.children, (child) => {
              // Проверяем, является ли child React-элементом
              if (React.isValidElement(child)) {
                return React.cloneElement(child, { onClose, onLink });
              }
              return child; // Возвращаем child без изменений, если это не React-элемент
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Modal;
export const MModal = motion.create(Modal);
