import React, { forwardRef } from "react";
import "../../css/neon-button.css";
import { motion } from "framer-motion";

const NeonButton = forwardRef((props, ref) => {
  return (
    <button ref={ref} {...props} className={"btn neon " + props.className}>
      {props.children}
    </button>
  );
});

export default NeonButton;
export const MNeonButton = motion.create(NeonButton);
