import React, { act, useContext, useEffect, useState } from "react";
import ShopFilter from "../components/MegaShop/ShopFilter";
import Catalog from "../components/MegaShop/Catalog";
import "../css/megashop.css";
import {
  dinosaurGrowths,
  dinosaurTypes,
  megaShopTypes,
  pageNames,
} from "../utils/consts";
import ComponentContainer from "../components/ComponentContainer";

import { observer } from "mobx-react-lite";
import { catalogData } from "../utils/megaShopItemsData";
import userStore from "../store/userStore";
import { megaShopStore } from "../store/megaShopStore";

const MegaShop = () => {
  useEffect(() => {
    userStore.setPageName(pageNames.MegaShop);
    megaShopStore.setData(catalogData);
    megaShopStore.setVisibleData(catalogData);
  }, []);

  return (
    <div className="megashop-page">
      <ComponentContainer className="navigation">
        <ShopFilter />
        <Catalog />
      </ComponentContainer>
    </div>
  );
};

export default observer(MegaShop);
