import React from "react";
import NavigationLinks from "./NavigationLinks";
import qiwi from "../assets/img/payment/qiwi 1.png";
import mc from "../assets/img/payment/mc 1.png";
import mir from "../assets/img/payment/mir 1.png";
import sbp from "../assets/img/payment/sbp 1.png";
import visa from "../assets/img/payment/visa 1.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {
  return (
    <footer className="center">
      <div>
        <p className="upper-case">Принимаем к оплате</p>
        <div className="payments">
          <LazyLoadImage effect="opacity" src={qiwi} alt="qiwi" />
          <LazyLoadImage effect="opacity" src={sbp} alt="sbp" />
          <LazyLoadImage effect="opacity" src={mir} alt="mir" />
          <LazyLoadImage effect="opacity" src={mc} alt="mc" />
          <LazyLoadImage effect="opacity" src={visa} alt="visa" />
        </div>
        <div className="footer-info">
          <span>© MEGALODON 2024</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
