import React, { useState } from "react";
import { ReactComponent as MultipleArrowsSvg } from "../assets/svg/multiple-arrows.svg";
import { ReactComponent as WalletSvg } from "../assets/svg/wallet.svg";
import { ReactComponent as TeethSvg } from "../assets/svg/teeth.svg";
import { ReactComponent as OaSvg } from "../assets/svg/oa.svg";
import { ReactComponent as DoneSvg } from "../assets/svg/done.svg";
import Button from "../components/Button";
import {
  notificationTimeouts,
  notificationTypes,
  valuteExchangeRates,
  valuteNames,
  valutes,
  valuteSvgs,
} from "../utils/consts";
import userStore from "../store/userStore";
import Dropdown from "../components/Dropdown";
import { observer } from "mobx-react-lite";
import "../css/changer.css";
import { showStandartNotification } from "../utils/helpers";

const Changer = (props) => {
  const fromValute = valutes.teeth;
  const toValute = valutes.oa;
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  function change(e) {
    const value = parseInt(e.target.value);
    setFromValue(value);
    setToValue(value * valuteExchangeRates[fromValute][toValute]);
  }

  function doChange() {
    if (fromValue > userStore.userValutes[fromValute]) {
      return showStandartNotification({
        text: "Невозможно перечислить больше чем у вас есть",
        type: notificationTypes.reject,
        timeout: notificationTimeouts.normal,
      });
    }
    if (fromValue <= 0) {
      return showStandartNotification({
        text: "Пожалуйста выберите положительное число",
        type: notificationTypes.reject,
        timeout: notificationTimeouts.normal,
      });
    }

    userStore.substractUserValutes({
      [fromValute]: fromValue,
    });
    userStore.addUserValutes({
      [toValute]: toValue,
    });

    setFromValue("");
    setToValue("");

    showStandartNotification({
      text: "Успешно",
      type: notificationTypes.success,
      timeout: notificationTimeouts.short,
    });
  }

  return (
    <div className="changer-item">
      <div className="d-flex changer">
        <input
          style={{
            color:
              fromValue > userStore.userValutes[fromValute] ? "red" : "inherit",
          }}
          className={`text-center border-extra`}
          type="number"
          placeholder={valuteNames[fromValute] || ""}
          min={0}
          max={userStore.userValutes[fromValute]}
          value={fromValue}
          onChange={(e) => change(e)}
          onKeyDown={(e) => e.key === "Enter" && doChange()}
        />
        <div className="valute from-valute">
          <TeethSvg /> Зубы
        </div>
      </div>
      <MultipleArrowsSvg />
      <div className="d-flex changer">
        <input
          type="number"
          className="text-center border-extra"
          placeholder={valuteNames[toValute] || ""}
          min={0}
          value={toValue}
        />
        <div className="valute to-valute">
          <OaSvg /> OA
        </div>
      </div>
      <Button
        className="gradient-background extra no-border spiked margin-0"
        onClick={doChange}
      >
        <DoneSvg />
      </Button>
    </div>
  );
};

export default observer(Changer);
