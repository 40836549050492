import React from "react";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import { Link } from "react-router-dom";
import userAvatar from "../../assets/img/avatar/Avatar_200.png";
import Button from "../../components/Button";
import { ReactComponent as DoneSvg } from "../../assets/svg/done.svg";
import "../../css/mega-profile.css";
import { ReactComponent as EditSvg } from "../../assets/svg/edit.svg";
import MegaCommon from "../../components/MegaProfile/MegaCommon";
import { usePageName } from "../../hooks/usePageName";
import { pageNames } from "../../utils/consts";
import MyLink from "../../components/MyLink";
import { LazyLoadImage } from "react-lazy-load-image-component";
import userStore from "../../store/userStore";
import { observer } from "mobx-react-lite";

const MegaProfile = () => {
  usePageName(pageNames.MegaProfile);

  return (
    <div className="page mega-profile-page mega-profile">
      <MegaCommon>
        {userStore.isAuth ? (
          <div className="user-info">
            <SmallComponentHeading className="wrap">
              Информация
              <MyLink to={"#"} className="small-text">
                <EditSvg /> редактировать
              </MyLink>
            </SmallComponentHeading>
            <div className="main-info">
              <div className="user-progress">
                <div className="avatar">
                  <LazyLoadImage
                    effect="opacity"
                    src={userStore.data.avatar}
                    alt={"avatar"}
                  />
                  <Button className="no-border spiked">ВНЕШНИЙ ВИД</Button>
                </div>
                <div className="user-main-info">
                  <div className="level title-content">
                    <div className="title">Уровень</div>
                    <div className="content gradient-background extra">55</div>
                  </div>
                  <div className="status title-content">
                    <div className="title">Статус пользователя</div>
                    <div className="content background-newbie">НОВИЧОК</div>
                  </div>
                  <div className="subscription title-content">
                    <div className="title">Статус подписки</div>
                    <div className="content background-standart">
                      НЕТ ПОДПИСКИ
                    </div>
                  </div>

                  <div className="premium title-content">
                    <div className="title">Премиум-аккаунт</div>
                    <div className="content background-standart">
                      ПРИОБРЕСТИ
                    </div>
                  </div>
                </div>
              </div>
              <div className="promocodes">
                <SmallComponentHeading>Промокоды</SmallComponentHeading>
                <div className="extra-input">
                  <input
                    type="text"
                    className="border-extra border-1 text-center"
                    placeholder={"введите промокод"}
                  />
                  <Button className="no-border extra">
                    <DoneSvg />
                  </Button>
                </div>
              </div>
              <div className="referrals">
                <SmallComponentHeading>
                  Реферальная программа
                </SmallComponentHeading>
                <div className="referrals-container">
                  <div className="referral-code title-content">
                    <div className="title">Ваш реферальный код</div>
                    <div className="content background-transparent border-extra">
                      6АJ9DS
                    </div>
                  </div>
                  <div className="user-referrals title-content">
                    <div className="title">Рефералов</div>
                    <div className="content background-transparent border-extra">
                      4
                    </div>
                  </div>
                  <div className="stage title-content">
                    <div className="title">Ваш этап</div>
                    <div className="content background-transparent border-extra">
                      I
                    </div>
                  </div>
                </div>
              </div>
              <div className="stages">
                <SmallComponentHeading>Награды и условия</SmallComponentHeading>
                <div className="stages-container">
                  <div className="first-stage">
                    <SmallComponentHeading className="gradient-text">
                      I этап
                    </SmallComponentHeading>
                    <p>
                      <b>Условие:</b> Набрать 100 очков активности рефералу
                    </p>
                    <p>
                      <b>Награда для реферера:</b> 40 зуб.
                    </p>
                    <p>
                      <b>Награда для реферала:</b> 30 зуб.
                    </p>
                  </div>
                  <div className="second-stage">
                    <SmallComponentHeading className="gradient-text">
                      II этап
                    </SmallComponentHeading>
                    <p>
                      <b>Условие:</b> Набрать 150 очков активности рефералу
                    </p>
                    <p>
                      <b>Награда для реферера:</b> 50 зуб., 1 динозавр режима
                      Survival (1 тир)
                    </p>
                    <p>
                      <b>Награда для реферала:</b> 40 зуб., 1 динозавр режима
                      Progression (1 тир)
                    </p>
                  </div>
                  <div className="third-stage">
                    <SmallComponentHeading className="gradient-text">
                      III этап
                    </SmallComponentHeading>
                    <p>
                      <b>Условие:</b> Набрать 200 очков активности рефералу
                    </p>
                    <p>
                      <b>Награда для реферера:</b> 60 зуб.
                    </p>
                    <p>
                      <b>Награда для реферала:</b> 50 зуб.
                    </p>
                  </div>
                  <div className="fourth-stage">
                    <SmallComponentHeading className="gradient-text">
                      IV этап
                    </SmallComponentHeading>
                    <p>
                      <b>Условие:</b> Набрать 200 очков активности рефералу
                    </p>
                    <p>
                      <b>Награда для реферера:</b> Слот, 100 TC, Тапбокс
                      Реферальный (1 шт.), 1 динозавр режима Survival, Премиум 1
                      день
                    </p>
                    <p>
                      <b>Награда для реферала:</b> Слот, 50 TC, 1 динозавр
                      режима Progression, Премиум 1 день
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p>Войдите в аккаунт</p>
          </div>
        )}
      </MegaCommon>
    </div>
  );
};

export default observer(MegaProfile);
