import React from "react";
import MyLink from "../MyLink";
import { paths } from "../../paths";
import { LazyLoadImage } from "react-lazy-load-image-component";
import userStore from "../../store/userStore";
import { Icon } from "@iconify/react";
import basketStore from "../../store/basketStore";
import avatar from "../../assets/img/avatar/Avatar_50.png";
import { ReactComponent as Oa } from "../../assets/svg/oa.svg";
import { ReactComponent as Teeth } from "../../assets/svg/teeth.svg";
import { observer } from "mobx-react-lite";
import Indicator from "../../templates/Indicator";
import SteamLogoutButton from "../Buttons/ActionButtons/SteamLogoutButton";

const UserHeader = (props) => {
  const teeth = userStore.userValutes.teeth || 0;
  const oa = userStore.userValutes.oa || 0;
  const basketCount = basketStore.itemCount;

  return (
    <div className="user-header">
      <SteamLogoutButton />
      <span className="teefs">
        {teeth} зуб. <Teeth />
      </span>
      <LazyLoadImage
        effect="opacity"
        src={userStore.data.avatar}
        alt="аватар"
        width={50}
        height={50}
        style={{ borderRadius: "50%" }}
      />
      <span className="oa">
        <Oa /> {oa} ОА
      </span>
      <MyLink to={paths.Basket} className="text-text svg-text">
        <span className="basket-icon">
          <Icon icon="el:shopping-cart" />
          {basketCount > 0 && (
            <Indicator className="basket-count">{basketCount}</Indicator>
          )}
        </span>
      </MyLink>
    </div>
  );
};

export default observer(UserHeader);
