import React, { useContext } from "react";
import {
  dinosaurTypes,
  filterNames,
  initialFilters,
  megaShopTypes,
} from "../../utils/consts";
import "../../css/active-filters.css";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import { isObservableMap, isObservableSet } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { megaShopStore } from "../../store/megaShopStore";

const ActiveFilters = (props) => {
  const checkWeights = (type, weight) => {
    if (type === "minWeight" && weight === 50) return false;
    if (type === "maxWeight" && weight === 50_000) return false;
    return true;
  };

  const renderFilterItems = (filter, i) => {
    if (
      isObservableSet(filter[1]) ||
      isObservableMap(filter[1]) ||
      filter[1] instanceof Set ||
      filter[1] instanceof Map
    ) {
      return Array.from(filter[1].entries()).map((item, i) => {
        return (
          checkWeights(item[0], item[1]) && (
            <div key={uuidv4()} className="active-filters-button">
              {item[0] !== item[1] && (
                <span className="filter-adjective">
                  {filterNames[item[0]] + " "}
                </span>
              )}
              <span className="filter-name">
                {filterNames[filter[0]] + ": "}
              </span>
              <span className="filter-item">
                {megaShopTypes[item[1]] || item[1]}
              </span>
              <button
                type="button"
                className="close"
                onClick={() => {
                  let newObj;
                  if (isObservableSet(filter[1])) {
                    newObj = new Set(filter[1]);
                    newObj.delete(item[0]);
                  } else if (isObservableMap(filter[1])) {
                    newObj = new Map(filter[1]);
                    if (item[0] === "minWeight") {
                      newObj.set(item[0], 50);
                    } else if (item[0] === "maxWeight") {
                      newObj.set(item[0], 50_000);
                    }
                  }
                  const newFilters = {
                    ...megaShopStore.filters,
                    [filter[0]]: newObj,
                  };
                  megaShopStore.setFilters(newFilters);
                }}
              />
            </div>
          )
        );
      });
    }
  };

  // Проверка, есть ли хотя бы один активный фильтр, который не имеет значений по умолчанию
  const hasActiveFilters = Object.entries(megaShopStore.filters).some(
    ([key, filter]) => {
      if (key === "weight") {
        // Проверка minWeight и maxWeight на их значения по умолчанию
        const minWeight = filter.get("minWeight");
        const maxWeight = filter.get("maxWeight");
        return minWeight !== 50 || maxWeight !== 50_000;
      }

      // Для остальных фильтров проверка на наличие значений
      if (isObservableSet(filter) || filter instanceof Set) {
        return filter.size > 0;
      } else if (isObservableMap(filter) || filter instanceof Map) {
        return filter.size > 0;
      } else if (typeof filter === "string") {
        return filter.length > 0;
      }
      return false;
    }
  );

  return (
    <div className="active-filters flex-container">
      {megaShopStore.filters &&
        Object.entries(megaShopStore.filters).map((filter, i) => {
          // Пропускаем пустые фильтры
          if (filter[1].size === 0 || filter[1].length === 0) return null;

          return (
            <div className="flex-container" key={i}>
              {renderFilterItems(filter, i)}
              {typeof filter[1] === "string" && filter[1].length > 0 && (
                <div className="active-filters-button">
                  <span className="filter-name">
                    {filterNames[filter[0]] + ": "}
                  </span>
                  <span className="filter-count">{filter[1]}</span>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      const newFilters = {
                        ...megaShopStore.filters,
                        [filter[0]]: "",
                      };
                      megaShopStore.setFilters(newFilters);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      {hasActiveFilters && (
        <div
          className="active-filters-button clear-all"
          onClick={() => megaShopStore.setFilters(initialFilters)}
        >
          <span className="filter-name">Очистить всё</span>
          <button type="button" className="close" />
        </div>
      )}
    </div>
  );
};

export default observer(ActiveFilters);
