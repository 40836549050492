import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import { contextValue } from "../utils/consts";
import Modal, { MModal } from "../components/Modal";
import { Context } from "..";
import { AnimatePresence } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { disableScroll, enableScroll } from "../utils/helpers";

const ModalWrapper = ({ props, onCloseParent }) => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    setShow(false);
    enableScroll();
    setTimeout(() => {
      onCloseParent(); // Закрытие родительского компонента после завершения анимации
      if (props.onClose) {
        props.onClose();
      }
    }, 300); // Задержка соответствует длительности анимации exit
  }, [onCloseParent, props]);

  const onLink = useCallback(
    (link, options = {}) => {
      console.log("navigated");
      onClose();
      if (link) {
        navigate(link, options);
      }
    },
    [navigate, onClose]
  );

  return (
    <Context.Provider value={contextValue}>
      <AnimatePresence>
        {show ? (
          <MModal
            key={uuidv4()}
            {...props}
            onClose={onClose}
            onLink={onLink}
            exit={{ opacity: 0, scale: 1.1 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            style={{ overflow: show ? "" : "hidden" }}
          />
        ) : null}
      </AnimatePresence>
    </Context.Provider>
  );
};

export const showModal = (props) => {
  const modalContainer = document.querySelector("#modal-container");

  const onClose = () => {
    console.log("showModal closed");
  };

  const modalElement = <ModalWrapper props={props} onCloseParent={onClose} />;

  disableScroll();

  // Используем React.createPortal для рендеринга модала
  return ReactDOM.createPortal(modalElement, modalContainer);
};
