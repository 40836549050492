import React, { useContext, useEffect, useState } from "react";
import { Context } from "..";
import Button from "./Button";
import { Icon } from "@iconify/react";
import { ReactComponent as TeethSvg } from "../assets/svg/teeth.svg";
import { ReactComponent as OaSvg } from "../assets/svg/oa.svg";
import basketStore from "../store/basketStore";
import { observer } from "mobx-react-lite";
import { initialCookieOptions } from "../utils/consts";
import { useCookies } from "react-cookie";
import "../css/to-basket.css";
import userStore from "../store/userStore";

const ToBasket = (props) => {
  //const { basketStore } = useContext(Context);
  const [cookies, setCookie, removeCookie] = useCookies(["basket"]);
  const [basketItems, setBasketItems] = useState(basketStore.items);
  const data = props.data || {};
  function handleSetCookie(key, data, options = initialCookieOptions) {
    const cookie = data;
    setCookie(key, cookie, options);
  }
  const handleRemoveCookie = (key, options = initialCookieOptions) => {
    removeCookie(key, options);
  };

  function handleAddToBasket() {
    basketStore.addToBasket(data);
    handleSetCookie("basket", basketStore.items);
  }

  function handleSetToBasket(amount) {
    basketStore.setToBasket(data, amount);
    handleSetCookie("basket", basketStore.items);
  }

  function handleRemoveFromBasket() {
    basketStore.removeOneFromBasket(data.id);
    handleSetCookie("basket", basketStore.items);
  }

  useEffect(() => {
    setBasketItems(basketStore.items);
  }, [basketStore.items]);

  return (
    <>
      {(data.cost || data.oa) && (
        <div className="to-basket">
          {userStore.isAuth ? (
            <>
              {!basketItems[data.id] && (
                <>
                  <Button
                    className="accent fullwidth rectangled gap-0-5"
                    onClick={() => handleAddToBasket()}
                  >
                    <Icon
                      icon="el:shopping-cart"
                      width="1.2rem"
                      height="1.2rem"
                    />{" "}
                    В корзину
                  </Button>
                </>
              )}
              {basketItems[data.id] && (
                <div className="in-basket d-flex justify-around">
                  <Button
                    className="change substract accent rectangled"
                    onClick={() => handleRemoveFromBasket()}
                  >
                    -
                  </Button>
                  <input
                    type="number"
                    min={1}
                    value={basketItems[data.id].amount}
                    onChange={(e) => handleSetToBasket(+e.target.value)}
                    className="amount"
                  />
                  <Button
                    className="change add accent rectangled"
                    onClick={() => handleAddToBasket()}
                  >
                    +
                  </Button>
                </div>
              )}
            </>
          ) : (
            <p className="w-100">Войдите чтобы добавить в корзину</p>
          )}
          <div className="price d-flex justify-around">
            <span className="teeth">
              <TeethSvg /> {data.cost}
            </span>
            {/* <span className="oa">
              <OaSvg /> {data.oa}
            </span> */}
          </div>
        </div>
      )}
    </>
  );
};

export default observer(ToBasket);
