import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import MiniHeading from "../Headings/MiniHeading";
import { ReactComponent as SearchSvg } from "../../assets/svg/search.svg";
import "../../css/shop-filter.css";
import ComponentContainer from "../ComponentContainer";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import {
  dinosaurGrowths,
  dinosaurTierClassNames,
  dinosaurTypes,
  megaShopTypes,
  notificationTimeouts,
  notificationTypes,
  tiers,
} from "../../utils/consts";
import { debounce, showStandartNotification } from "../../utils/helpers";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import { megaShopStore } from "../../store/megaShopStore";
import DoubleRange from "../../templates/DoubleRange";
import { catalogCategories } from "../../utils/megaShopItemsData";
import { v4 as uuidv4 } from "uuid";

const ShopFilter = (props) => {
  const [minWeightValue, setMinWeightValue] = useState(
    megaShopStore.filters.weight.get("minWeight") || ""
  );
  const [maxWeightValue, setMaxWeightValue] = useState(
    megaShopStore.filters.weight.get("maxWeight") || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    megaShopStore.filters.search || ""
  );

  const minWeight = 50;
  const maxWeight = 50_000;
  const weightStep = 50;

  function toggleTypes(type) {
    const newFilters = {
      ...megaShopStore.filters,
      type: new Set(megaShopStore.filters.type),
    };

    if (newFilters.type.has(type)) {
      newFilters.type.delete(type);
    } else {
      newFilters.type.add(type);
    }

    megaShopStore.setFilters(newFilters);
  }

  function toggleTiers(tier) {
    const newFilters = {
      ...megaShopStore.filters,
      tier: new Set(megaShopStore.filters.tier),
    };
    newFilters.tier.has(tier)
      ? newFilters.tier.delete(tier)
      : newFilters.tier.add(tier);
    megaShopStore.setFilters(newFilters);
  }

  function toggleGrowth(growth) {
    const newFilters = {
      ...megaShopStore.filters,
      growth: new Set(megaShopStore.filters.growth),
    };
    newFilters.growth.has(growth)
      ? newFilters.growth.delete(growth)
      : newFilters.growth.add(growth);
    megaShopStore.setFilters(newFilters);
  }

  const debouncedSetFilters = debounce((newFilters) => {
    megaShopStore.setFilters(newFilters);
  }, 500);

  const changeWeight = useCallback(
    ({ min, max }) => {
      const newFilters = {
        ...megaShopStore.filters,
        weight: new Map(megaShopStore.filters.weight),
      };

      if (min !== minWeightValue) {
        if (min < minWeight) {
          return showStandartNotification({
            text: "Минимальный вес динозавра 50кг",
            timeout: notificationTimeouts.short,
            type: notificationTypes.reject,
          });
        }

        newFilters.weight.set("minWeight", min);
        setMinWeightValue(min);
      }

      if (max !== maxWeightValue) {
        if (max > maxWeight) {
          return showStandartNotification({
            text: "Максимальный вес динозавра 50 тонн",
            timeout: notificationTimeouts.short,
            type: notificationTypes.reject,
          });
        }
        newFilters.weight.set("maxWeight", max);
        setMaxWeightValue(max);
      }

      if (min !== minWeightValue || max !== maxWeightValue) {
        debouncedSetFilters(newFilters);
      }
    },
    [minWeightValue, maxWeightValue, minWeight, maxWeight, debouncedSetFilters]
  );

  const debouncedSetQuery = debounce((newFilters) => {
    megaShopStore.setFilters(newFilters);
  }, 500);

  function changeSearchQuery(query) {
    const newFilters = {
      ...megaShopStore.filters,
      search: query,
    };
    setSearchQuery(query);
    debouncedSetQuery(newFilters);
  }

  useEffect(() => {
    setMinWeightValue(megaShopStore.filters.weight.get("minWeight"));
    setMaxWeightValue(megaShopStore.filters.weight.get("maxWeight"));
    setSearchQuery(megaShopStore.filters.query);

    const filteredData = megaShopStore.data.filter((item) => {
      // if (
      //   megaShopStore.filters.type?.size > 0 &&
      //   !megaShopStore.filters.type.has(item.type)
      // )
      //   return false;

      // if (
      //   megaShopStore.filters.tier?.size > 0 &&
      //   item.tier &&
      //   !megaShopStore.filters.tier.has(item.tier)
      // )
      //   return false;
      if (
        megaShopStore.filters.categories?.size > 0 &&
        item.categories &&
        !megaShopStore.filters.categories.has(item.categories)
      )
        return false;
      if (
        item.grow &&
        (item.grow < megaShopStore.filters.weight.get("minWeight") ||
          item.grow > megaShopStore.filters.weight.get("maxWeight"))
      )
        return false;
      if (
        megaShopStore.filters.growth?.size > 0 &&
        item.growth &&
        !megaShopStore.filters.growth.has(item.growth)
      )
        return false;
      if (
        megaShopStore.filters.search &&
        !item.name
          ?.toLowerCase()
          .includes(megaShopStore.filters.search.toLowerCase()) &&
        !item.description
          ?.toLowerCase()
          .includes(megaShopStore.filters.search.toLowerCase)
      )
        return false;
      return true;
    });

    megaShopStore.setVisibleData(filteredData);
  }, [megaShopStore.filters]);

  return (
    <div className="shop-filter filter">
      <SmallComponentHeading>Фильтр</SmallComponentHeading>
      <div className="search">
        <button type="button">
          <SearchSvg />
        </button>
        <input
          type="search"
          placeholder="Поиск по названию"
          onChange={(e) => changeSearchQuery(e.target.value)}
          value={searchQuery}
        />
      </div>
      <div className="section type">
        <div className="section-title">Тип</div>
        <ul className="no-marker">
          {Object.values(catalogCategories).map((item, i) => (
            <li key={uuidv4()}>
              {console.log(item)}
              <input
                type="checkbox"
                checked={megaShopStore.filters.type.has(item)}
                id={item}
                onChange={() => toggleTypes(item)}
              />
              <label htmlFor={item}>{item}</label>
            </li>
          ))}
          {/* <li>
            <input
              type="checkbox"
              checked={megaShopStore.filters.type.has(
                dinosaurTypes.herbivorous
              )}
              id={dinosaurTypes.herbivorous}
              onChange={() => toggleTypes(dinosaurTypes.herbivorous)}
            />
            <label htmlFor={dinosaurTypes.herbivorous}>Травоядные</label>
          </li>
          <li>
            <input
              type="checkbox"
              id={dinosaurTypes.predator}
              onChange={() => toggleTypes(dinosaurTypes.predator)}
              checked={megaShopStore.filters.type.has(dinosaurTypes.predator)}
            />
            <label htmlFor={dinosaurTypes.predator}>Хищники</label>
          </li>
          <li>
            <input
              type="checkbox"
              id={megaShopTypes.case}
              onChange={() => toggleTypes(megaShopTypes.case)}
              checked={megaShopStore.filters.type.has(megaShopTypes.case)}
            />
            <label htmlFor={megaShopTypes.case}>Кейсы</label>
          </li>
          <li>
            <input
              type="checkbox"
              id={megaShopTypes.buster}
              onChange={() => toggleTypes(megaShopTypes.buster)}
              checked={megaShopStore.filters.type.has(megaShopTypes.buster)}
            />
            <label htmlFor={megaShopTypes.buster}>Бустеры</label>
          </li>
          <li>
            <input
              type="checkbox"
              id={megaShopTypes.egg}
              onChange={() => toggleTypes(megaShopTypes.egg)}
              checked={megaShopStore.filters.type.has(megaShopTypes.egg)}
            />
            <label htmlFor={megaShopTypes.egg}>Яйца</label>
          </li> */}
        </ul>
      </div>
      <div className="section tier">
        <div className="section-title">ТИР</div>
        <ul className="no-marker">
          <li>
            <input
              type="checkbox"
              id={tiers[1]}
              onChange={() => toggleTiers(1)}
              checked={megaShopStore.filters.tier.has(1)}
            />
            <label htmlFor={tiers[1]}>I</label>
          </li>
          <li>
            <input
              type="checkbox"
              id={tiers[2]}
              onChange={() => toggleTiers(2)}
              checked={megaShopStore.filters.tier.has(2)}
            />
            <label htmlFor={tiers[2]}>II</label>
          </li>
          <li>
            <input
              type="checkbox"
              id={tiers[3]}
              onChange={() => toggleTiers(3)}
              checked={megaShopStore.filters.tier.has(3)}
            />
            <label htmlFor={tiers[3]}>III</label>
          </li>
          <li>
            <input
              type="checkbox"
              id={tiers[4]}
              onChange={() => toggleTiers(4)}
              checked={megaShopStore.filters.tier.has(4)}
            />
            <label htmlFor={tiers[4]}>IV</label>
          </li>
        </ul>
      </div>
      <div className="section growth">
        <div className="section-title">РОСТ</div>
        <ul className="no-marker">
          <li>
            <input
              type="checkbox"
              id={dinosaurGrowths.Sub}
              onChange={() => toggleGrowth(dinosaurGrowths.Sub)}
              checked={megaShopStore.filters.growth.has(dinosaurGrowths.Sub)}
            />
            <label htmlFor={dinosaurGrowths.Sub}>Sub</label>
          </li>
          <li>
            <input
              type="checkbox"
              id={dinosaurGrowths.Adult}
              onChange={() => toggleGrowth(dinosaurGrowths.Adult)}
              checked={megaShopStore.filters.growth.has(dinosaurGrowths.Adult)}
            />
            <label htmlFor={dinosaurGrowths.Adult}>Adult</label>
          </li>
        </ul>
      </div>
      <div className="section weight">
        <div className="section-title">ВЕС</div>
        <div className="weight">
          <DoubleRange
            min={50}
            max={50_000}
            step={50}
            onChange={(minMax) => changeWeight(minMax)}
            minValue={minWeightValue}
            maxValue={maxWeightValue}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ShopFilter);
