import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { independentPaths, paths } from "../paths";
import Dropdown from "./Dropdown";
import { Context } from "..";
import { dropdownVariants, serverUrls } from "../utils/consts";
import Offcanvas from "./Offcanvas";
import "../css/navigation-links.css";
import { observer } from "mobx-react-lite";
import MyLink, { MMYLink } from "./MyLink";
import userStore from "../store/userStore";

const NavigationLinks = (props) => {
  const location = useLocation();
  const page = location.pathname;
  const up = props.up !== undefined;

  return (
    <Offcanvas head={"МЕНЮ"} className="navigation-links resp-lg">
      <MyLink
        to={paths.Home}
        className={
          page === userStore.choosedServerUrl + paths.Home ? "active" : ""
        }
      >
        ГЛАВНАЯ
      </MyLink>
      <Link
        isDisabled={true}
        to={
          userStore.choosedServerUrl === serverUrls.legacy
            ? independentPaths.Rules
            : independentPaths.RulesEvrima
        }
        className={`disabled ${
          page === independentPaths.Rules ||
          page === independentPaths.RulesEvrima
            ? "active"
            : ""
        }`}
      >
        ПРАВИЛА
      </Link>
      <MyLink
        to={paths.MegaShop}
        className={
          page === userStore.choosedServerUrl + paths.MegaShop ? "active" : ""
        }
      >
        МЕГАШОП
      </MyLink>
      <Dropdown value="МЕГАПРОФИЛЬ" up={up} animated={true}>
        <MMYLink
          to={paths.MegaProfile}
          className={
            page === userStore.choosedServerUrl + paths.MegaProfile
              ? "active"
              : ""
          }
        >
          МОЙ МЕГАПРОФИЛЬ
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.MyDinosaurs}
          className={`disabled ${
            page === userStore.choosedServerUrl + paths.MyDinosaurs
              ? "active"
              : ""
          }`}
        >
          МОИ ДИНОЗАВРЫ
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.MyStorage}
          className={`disabled ${
            page === userStore.choosedServerUrl + paths.MyStorage
              ? "active"
              : ""
          }`}
        >
          ХРАНИЛИЩЕ
        </MMYLink>
        <MMYLink
          to={paths.MegaBank}
          className={
            page === userStore.choosedServerUrl + paths.MegaBank ? "active" : ""
          }
        >
          МЕГАБАНК
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.MyStatistics}
          className={`disabled ${
            page === userStore.choosedServerUrl + paths.MyStatistics
              ? "active"
              : ""
          }`}
        >
          МОЯ АКТИВНОСТЬ
        </MMYLink>
      </Dropdown>
      <Dropdown value="СЕРВЕР" up={up} animated={true} isDisabled={true}>
        <MMYLink
          isDisabled={true}
          to={paths.UsersLeaderboard}
          className={`disabled ${
            page === userStore.choosedServerUrl + paths.UsersLeaderboard
              ? "active"
              : ""
          }`}
        >
          Рейтинг игроков
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.DinosaursLeaderboard}
          className={`disabled ${
            page === userStore.choosedServerUrl + paths.DinosaursLeaderboard
              ? "active"
              : ""
          }`}
        >
          Рейтинг динозавров
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.Achievements}
          className={`disabled ${
            page === userStore.choosedServerUrl + paths.Achievements
              ? "active"
              : ""
          }`}
        >
          Достижения
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.BattlePass}
          className={`disabled 
            ${
              page === userStore.choosedServerUrl + paths.BattlePass
                ? "active"
                : ""
            }`}
        >
          Боевой пропуск
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.FortuneWheel}
          className={`disabled 
          ${
            page === userStore.choosedServerUrl + paths.FortuneWheel
              ? "active"
              : ""
          }`}
        >
          Колесо удачи
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.Administration}
          className={`disabled 
            ${
              page === userStore.choosedServerUrl + paths.Administration
                ? "active"
                : ""
            }`}
        >
          Администрация
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.Offers}
          className={`disabled 
          ${
            page === userStore.choosedServerUrl + paths.Offers ? "active" : ""
          }`}
        >
          Предложения
        </MMYLink>
        <MMYLink
          isDisabled={true}
          to={paths.AdminPanel}
          className={`disabled 
            ${
              page === userStore.choosedServerUrl + paths.AdminPanel
                ? "active"
                : ""
            }`}
        >
          Админ панель
        </MMYLink>
      </Dropdown>
      <MyLink
        isDisabled={true}
        to={paths.Ivents}
        className={`disabled 
          ${
            page === userStore.choosedServerUrl + paths.Ivents ? "active" : ""
          }`}
      >
        ИВЕНТЫ
      </MyLink>
      <MyLink
        isDisabled={true}
        to={paths.Guilds}
        className={`disabled 
          ${
            page === userStore.choosedServerUrl + paths.Guilds ? "active" : ""
          }`}
      >
        ГИЛЬДИИ
      </MyLink>
    </Offcanvas>
  );
};

export default observer(NavigationLinks);
