import React, { forwardRef } from "react";
import MiniHeading from "./Headings/MiniHeading";
import "../css/possibility-card.css";
import { motion } from "framer-motion";

const PossibilityCard = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="possibility-card">
      {props.image && (
        <div className="possibility-card-image">{props.image}</div>
      )}
      <div className="possibility-card-text">
        {props.title && <b>{props.title}</b>}
        {props.text && <p>{props.text}</p>}
      </div>
    </div>
  );
});

export default PossibilityCard;
export const MPossibilityCard = motion.create(PossibilityCard);
