import React from "react";
import ComponentContainer from "../ComponentContainer";
import UserMenu from "../UserMenu/UserMenu";

const MegaCommon = (props) => {
  return (
    <ComponentContainer className="navigation">
      <UserMenu mega={true} />
      {props.children}
    </ComponentContainer>
  );
};

export default MegaCommon;
