import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import Button from "../Button";
import basketStore from "../../store/basketStore";
import { useNavigate } from "react-router-dom";
import userStore from "../../store/userStore";
import { paths } from "../../paths";
import {
  getGrammaticalEnding,
  showStandartNotification,
} from "../../utils/helpers";
import { v4 as uuidv4 } from "uuid";
import AttentionCard from "../Cards/AttentionCards/AttentionCard";
import { ReactComponent as TeethSvg } from "../../assets/svg/teeth.svg";
import { ReactComponent as OaSvg } from "../../assets/svg/oa.svg";
import {
  cookieNames,
  initialCookieOptions,
  notificationTimeouts,
  notificationTypes,
  valutes,
} from "../../utils/consts";
import { transaction } from "mobx";
import MyLink from "../MyLink";
import { useCookies } from "react-cookie";

const BasketCheckedInfo = (props) => {
  const [cookie, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const isConfirm = props.isConfirm;

  const checkedItems = useMemo(
    () =>
      Object.entries(basketStore.items)
        .filter(([id]) => basketStore.checkedItemsIds.has(+id))
        .map(([, item]) => item),
    [basketStore.checkedItemsIds, basketStore.items]
  );

  const goToConfirm = useCallback(() => {
    basketStore.prepareForCheckout();
    navigate(userStore.choosedServerUrl + paths.BasketConfirm);
  }, [navigate]);

  function payProducts() {
    const isEnough = props.isEnoughMoney();
    if (isEnough) {
      const backupUserValutes = { ...userStore.userValutes };
      const backupStorage = [...userStore.storage];
      const backupBasketItems = { ...basketStore.items };
      const backupTotalPriceOa = basketStore.totalPriceOa;
      const backupTotalPriceTeeth = basketStore.totalPriceTeeth;

      try {
        transaction(async () => {
          await userStore.addToStorage(checkedItems);
          if (props.choosedPaymentType === valutes.teeth) {
            userStore.substractUserValutes({
              [valutes.teeth]: basketStore.totalPriceTeeth,
            });
          } else if (props.choosedPaymentType === valutes.oa) {
            userStore.substractUserValutes({
              [valutes.oa]: basketStore.totalPriceOa,
            });
          }

          basketStore.removeItems(checkedItems);
          basketStore.clearCheckedItems();
        });

        //setCookie(cookieNames.storage, userStore.storage, initialCookieOptions);
        setCookie(cookieNames.basket, basketStore.items, initialCookieOptions);

        navigate(userStore.choosedServerUrl + paths.MyStorage, {
          replace: true,
        });

        showStandartNotification({
          text: "Успешная покупка",
          type: notificationTypes.done,
          timeout: notificationTimeouts.short,
        });
      } catch (error) {
        // Восстанавливаем предыдущее состояние
        userStore.setUserValutes(backupUserValutes);
        userStore.setStorage(backupStorage);
        basketStore.setItems(backupBasketItems);
        basketStore.setTotalPriceOa(backupTotalPriceOa);
        basketStore.setTotalPriceTeeth(backupTotalPriceTeeth);

        // Показываем уведомление об ошибке
        showStandartNotification({
          text: "Произошла ошибка при оплате",
          timeout: notificationTimeouts.normal,
          type: notificationTypes.reject,
        });
        console.error("Ошибка при оплате: ", error);
      }
    } else if (!isEnough) {
      showStandartNotification({
        text: "Недостаточно средств",
        timeout: notificationTimeouts.normal,
        type: notificationTypes.reject,
      });
    }
  }

  return (
    <div className="basket-info right-column">
      {checkedItems.length > 0 && (
        <>
          <div className="basket-info-total">
            {isConfirm ? (
              <>
                <p className="text-dangerous">
                  Оплата через сайт пока недоступна
                </p>
                <Button
                  className="rectangled fullwidth disabled"
                  // onClick={() => payProducts()}
                >
                  Оплатить заказ
                </Button>
                <p className="misc mt-0-5 mb-0-5">
                  <span>
                    Нажимая на кнопку, вы соглашаетесь с<br />
                    <MyLink>Условиями обработки персональных данных</MyLink>
                    ,<br />а также с <MyLink>Условиями продажи</MyLink>
                  </span>
                </p>
              </>
            ) : (
              <Button
                className="rectangled fullwidth"
                onClick={() => {
                  goToConfirm();
                }}
              >
                Перейти к оформлению
              </Button>
            )}
            <div className="total-basket">
              <p className="basket-total-name d-flex justify-between">
                <span className="bold">
                  {isConfirm ? "Ваш заказ" : "Ваша корзина"}
                </span>
                <span className="text-dark font-size-0-75">
                  {checkedItems.length}{" "}
                  {getGrammaticalEnding(checkedItems.length, [
                    "товар",
                    "товара",
                    "товаров",
                  ])}
                </span>
              </p>
              <div className="checked-items mb-1">
                <p>
                  <span>Товары ({checkedItems.length})</span>
                </p>
                {checkedItems.map((item) => (
                  <p key={uuidv4()} className="d-flex justify-between gap-1">
                    <span>
                      {item.name_ru || "Товар"} x{item.amount}
                    </span>
                    <span className="d-flex gap-0-25">
                      <span>
                        <TeethSvg /> {item.amount * item.cost},
                      </span>
                      {/* <span>
                        <OaSvg /> {item.amount * item.oa}
                      </span> */}
                    </span>
                  </p>
                ))}
              </div>
            </div>
            <p className="basket-info-total-text d-flex justify-between gap-1">
              <span>{isConfirm ? "Итого:" : "Общая стоимость:"}</span>
              <span>
                <span>
                  <TeethSvg /> {basketStore.totalPriceTeeth}
                </span>
                {/* <span className="ml-0-5">
                  <OaSvg /> {basketStore.totalPriceOa}
                </span> */}
              </span>
            </p>
          </div>
        </>
      )}
      {checkedItems.length === 0 && (
        <>
          <div className="basket-info-total">
            <Button className="rectangled fullwidth disabled" disabled>
              Перейти к оформлению
            </Button>
            <AttentionCard>
              Выберите товары, чтобы перейти к оформлению заказа
            </AttentionCard>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(BasketCheckedInfo);
