import basketStore from "../store/basketStore";
import MegaShopStore, { megaShopStore } from "../store/megaShopStore";
import userStore from "../store/userStore";
import { ReactComponent as WalletSvg } from "../assets/svg/wallet.svg";
import { ReactComponent as TeethSvg } from "../assets/svg/teeth.svg";
import { ReactComponent as OaSvg } from "../assets/svg/oa.svg";
import qiwi from "../assets/img/payment/qiwi 1.png";
import mc from "../assets/img/payment/mc 1.png";
import mir from "../assets/img/payment/mir 1.png";
import sbp from "../assets/img/payment/sbp 1.png";
import visa from "../assets/img/payment/visa 1.png";
import { ReactComponent as YookassaSvg } from "../assets/svg/paymentTypes/yookassa.svg";

import { LazyLoadImage } from "react-lazy-load-image-component";

export const wpImagePath = ""; // "/wp-content/reactpress/apps/the-isle/build/";

export const steamProfile =
  "https://steamcommunity.com/profiles/76561198359008960";

export const steamProfileUrl = "https://steamcommunity.com/profiles/";

export const serverNames = {
  Evrima: "Evrima",
  Legacy: "Legacy",
};

export const serverUrls = {
  evrima: "/evrima",
  legacy: "/legacy",
};

export const dinosaurTypes = {
  herbivorous: "herbivorous",
  predator: "predator",
};

export const dinosaurSizes = {
  smallest: "smallest",
  small: "small",
  normal: "normal",
  big: "big",
  largest: "largest",
};

export const dinosaurTierClassNames = {
  1: "success",
  2: "accent",
  3: "sub-accent",
  4: "dangerous",
};

export const dinosaurGrowths = {
  Sub: "Sub",
  Adult: "Adult",
};

export const tiers = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
};

export const megaShopTypes = {
  herbivorous: "Травоядный",
  predator: "Хищник",
  case: "Кейс",
  buster: "Бустер",
  egg: "Яйцо",
};

export const filterNames = {
  type: "Тип",
  tier: "Тир",
  weight: "Вес",
  minWeight: "Минимальный",
  maxWeight: "Максимальный",
  growth: "Рост",
  search: "Поиск",
};

export const megaShopCharacteristics = {
  tier: "Тир",
  weight: "Вес, кг",
  growth: "Рост",
  type: "Тип",
  mode: "Режим",
  health: "Здоровье, HP",
  damage: "Базовый урон",
  speed: "Скорость бега, км/ч",
  endurance: "Выносливость",
  hunger: "Голод, мин",
  thirst: "Жажда, мин",
  server: "Ветка игры",
  growthTime: "Продолжительность роста (стадия), мин",
  grow: "Рост",
};

export const responsiveSizes = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  xxl: "xxl",
};

export const pageNames = {
  Home: "",
  TransitionPage: "",
  Rules: "Правила",
  MegaProfile: "МегаПрофиль",
  MegaShop: "МегаШоп",
  UsersLeaderboard: "Рейтинг игроков",
  DinosaursLeaderboard: "Рейтинг динозавров",
  Profile: "МегаПрофиль",
  Ivents: "Ивенты",
  Guilds: "Гильдии",
  GuildsCreate: "Создание Гильдии",
  Achievements: "Достижения",
  Administration: "Администрация",
  Offers: "Предложения",
  BattlePass: "Боевой пропуск",
  FortuneWheeel: "Колесо удачи",
  AdminPanel: "Панель Администратора",
};

export const pageAdditions = {
  Home: "ДОБРО ПОЖАЛОВАТЬ НА",
  TransitionPage: "ДОБРО ПОЖАЛОВАТЬ НА",
};

export const infoCardTypes = {
  guild: "guild",
  ivent: "ivent",
};

export const guildLevelTexts = {
  5: "Эксперт I",
  7: "Эксперт I",
  10: "Эксперт III",
};

export const guildLevelThemes = {
  5: "accent",
  7: "extra",
  10: "dangerous",
};

export const sizeTypes = {
  compressed: "compressed",
  expanded: "expanded",
};

export const statusClasses = {
  pending: "pending gradient-text",
  declined: "declined gradient-text dangerous",
  accepted: "accepted gradient-text success",
};

export const statusTexts = {
  pending: "ожидает",
  declined: "отказано",
  accepted: "Заявка одобрена",
};

export const attentionCardTypes = {
  adminPanel: "adminPanel",
  offers: "offers",
};

export const attentionCardStatuses = {
  active: "active",
  accepted: "accepted",
  declined: "declined",
  onModeration: "onModeration",
  closed: "closed",
};

export const valutes = {
  teeth: "teeth",
  oa: "oa",
  rubles: "rubles",
};

export const valuteExchangeRates = {
  [valutes.teeth]: {
    [valutes.oa]: 5,
  },
  [valutes.rubles]: {
    [valutes.teeth]: 1,
  },
};

export const valuteNames = {
  [valutes.oa]: "ОА",
  [valutes.teeth]: "Зубы",
  [valutes.rubles]: "Рубли",
};

export const valuteSvgs = {
  [valutes.teeth]: <TeethSvg />,
  [valutes.oa]: <OaSvg />,
  [valutes.rubles]: <WalletSvg />,
};

export const notificationTypes = {
  classic: "classic",
  done: "done",
  reject: "reject",
};

export const notificationTimeouts = {
  short: 5000,
  normal: 10000,
  large: 15000,
};

export const contextValue = {
  userStore,
  megaShopStore,
  basketStore,
};

export const initialCookieOptions = { path: userStore.choosedServerUrl };

export const scrollbarWidth = 9;

export const paymentSystems = {
  mir: "mir",
  visa: "visa",
  mastercard: "mastercard",
  qiwi: "qiwi",
  sbp: "sbp",
};

export const paymentSystemsNames = {
  [paymentSystems.mir]: "МИР",
  [paymentSystems.visa]: "Visa",
  [paymentSystems.mastercard]: "Mastercard",
  [paymentSystems.qiwi]: "Qiwi",
  [paymentSystems.sbp]: "СБП",
};

export const paymentSystemsImages = {
  [paymentSystems.qiwi]: (
    <LazyLoadImage effect="opacity" src={qiwi} alt="qiwi" />
  ),
  [paymentSystems.sbp]: <LazyLoadImage effect="opacity" src={sbp} alt="sbp" />,
  [paymentSystems.mir]: <LazyLoadImage effect="opacity" src={mir} alt="mir" />,
  [paymentSystems.mastercard]: (
    <LazyLoadImage effect="opacity" src={mc} alt="mastercard" />
  ),
  [paymentSystems.visa]: (
    <LazyLoadImage effect="opacity" src={visa} alt="visa" />
  ),
};

export const cookieNames = {
  basket: "basket",
  storage: "storage",
  accessToken: "accessToken",
};

export const initialFilters = {
  type: new Set(),
  tier: new Set(),
  weight: new Map([
    ["minWeight", 50],
    ["maxWeight", 50000],
  ]),
  search: "",
  growth: new Set(),
};

export const popupDirections = {
  top: "top",
  bottom: "bottom",
  left: "left",
  right: "right",
};

export const paymentTypesNames = {
  yookassa: "ЮКасса",
};

export const paymentTypesImages = {
  [paymentTypesNames.yookassa]: <YookassaSvg />,
};
