import React, { useCallback, useEffect, useRef, useState } from "react";
import "../css/dropdown.css";
import { ReactComponent as ArrowDown } from "../assets/svg/arrow_down.svg";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { dropdownVariants } from "../utils/consts";
import { debounce } from "../utils/helpers";
import { v4 as uuidv4 } from "uuid";

const itemVariants = {
  open: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 24,
      delay: index * 0.05,
    },
  }),
  closed: {
    opacity: 0,
    y: 20,
    transition: { duration: 0.2 },
  },
};

const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUp, setIsUp] = useState(false);
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);
  const isDisabled = props.isDisabled;

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Проверка размещения меню
  const checkDropdownPosition = useCallback(() => {
    if (dropdownRef.current && contentRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect(); // Положение dropdown
      const contentRect = contentRef.current.getBoundingClientRect(); // Положение content
      const viewportHeight = window.innerHeight; // Высота экрана

      // Если контент снизу, проверяем, влезает ли он вниз
      if (!isUp) {
        const dropdownBottom = contentRect.bottom;
        const dropdownTop =
          dropdownRect.top - (contentRect.bottom - dropdownRect.top);

        if (dropdownBottom > viewportHeight && dropdownTop > 0) {
          // Если не помещается вниз и влезает вверх, показываем вверх
          setIsUp(true);
        }
      }

      // Если контент сверху, проверяем, влезает ли он снизу или влезает ли вверх
      else if (isUp) {
        const dropdownBottom =
          dropdownRect.bottom + (dropdownRect.top - contentRect.top);

        if (dropdownBottom < viewportHeight || contentRect.top < 0) {
          setIsUp(false);
        }
      }
    }
  }, [isUp]);

  const toggleDropdown = () => {
    if (!isDisabled) {
      checkDropdownPosition(); // Проверка при клике
      setIsOpen((prev) => !prev);
    }
  };

  // Проверка при прокрутке
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (isOpen) {
        checkDropdownPosition();
      }
    }, 200);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, checkDropdownPosition]);

  return (
    <motion.div
      ref={dropdownRef}
      onClick={toggleDropdown}
      className={`dropdown active-handler${isOpen ? " active" : ""}${
        isUp ? " up" : ""
      } ${props.className ? props.className : ""}${props.up ? " up" : ""}`}
      initial={false}
      animate={isOpen ? "open" : "closed"}
    >
      <motion.button
        whileTap={{ scale: 0.95 }}
        type="button"
        className={`dropdown-button${isDisabled ? " disabled" : ""}`}
      >
        {props.value}
        {!props.noArrow && <ArrowDown className="arrow" />}
      </motion.button>
      <div ref={contentRef} className="dropdown-content">
        <div className="arrow">
          <Icon icon="bxs:up-arrow" className="svg-accent" />
        </div>

        <motion.div
          key={uuidv4()}
          className="dropdown-content-container"
          variants={
            props.animated
              ? {
                  open: {
                    clipPath: "inset(-10% 0% 0% 0% round 10px)",
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 0.7,
                      delayChildren: 0.3,
                      staggerChildren: 0.05,
                    },
                  },
                  closed: {
                    clipPath: "inset(10% 50% 90% 50% round 10px)",
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 0.3,
                    },
                  },
                }
              : undefined
          }
          initial={props.animated ? "closed" : undefined}
          animate={props.animated ? (isOpen ? "open" : "closed") : undefined}
          style={
            !props.animated
              ? {
                  clipPath: isOpen ? "inset(-10% 0% 0% 0% round 10px)" : "none",
                }
              : undefined
          }
        >
          {React.Children.map(props.children, (child, index) => (
            <React.Fragment key={uuidv4()}>{child}</React.Fragment>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Dropdown;
