import { BrowserRouter } from "react-router-dom";
import TransitionLayer from "./components/TransitionLayer";
import Header from "./components/Header/Header";
import Footer from "./components/Footer";
import AppRouter from "./components/AppRouter";
import "./css/App.css";
import "./css/media-app.css";
import { scripts } from "./utils/scripts";
import ScrollToTopButton from "./components/ScrollToTop";
import { CookiesProvider, useCookies } from "react-cookie";
import { useEffect, useMemo } from "react";
import basketStore from "./store/basketStore";
import { useSmoothScroll } from "./hooks/useSmoothScroll";
import userStore from "./store/userStore";
import userApi from "./api/userApi";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  //useSmoothScroll();

  // Вход в аккаунт стим при заходе на сайт
  useEffect(() => {
    (async () => {
      try {
        if (!userStore.isAuth) {
          const userData = await userApi.getUserInfo();
          if (userData) {
            userStore.setIsAuth(true);
            userStore.setData(userData);
          }
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  // корзина из куков помещается в store
  useEffect(() => {
    if (!basketStore.isBasketSetted) {
      const basketCookie = cookies.basket;

      if (basketCookie) {
        try {
          const basket = basketCookie;
          console.log(typeof basket, basket);
          basketStore.setItems(basket);
          basketStore.setIsBasketSetted(true);
        } catch (error) {
          console.error("Ошибка при разборе JSON:", error);
        }
      }
    }
  }, []);

  return (
    <CookiesProvider>
      <BrowserRouter>
        <TransitionLayer />
        <div className="page-container">
          <Header />
          <AppRouter />
          <Footer />
        </div>
        <ScrollToTopButton />
        <div id="notification-container"></div>
        <div id="modal-container"></div>
        <div id="offcanvas-container"></div>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
