import React from "react";
import { Route, Routes } from "react-router-dom";
import { independentRoutes, routes } from "../routes";
import { serverUrls } from "../utils/consts";
import { v4 as uuidv4 } from "uuid";

const AppRouter = () => {
  return (
    <Routes>
      {routes.map(({ path, Component }) => (
        <>
          <Route
            key={serverUrls.evrima + path}
            path={serverUrls.evrima + path}
            element={<Component />}
          />
          <Route
            key={serverUrls.legacy + path}
            path={serverUrls.legacy + path}
            element={<Component />}
          />
        </>
      ))}
      {independentRoutes.map(({ path, Component }) => (
        <Route key={uuidv4()} path={path} element={<Component />} />
      ))}
    </Routes>
  );
};

export default AppRouter;
