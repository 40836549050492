import { $authHost } from ".";

class UserApi {
  async getUserInfo() {
    const { data } = await $authHost.get("/user/user-info");
    return data;
  }

  async logout() {
    const { data } = await $authHost.post("/user/logout");
    return data;
  }
}

const userApi = new UserApi();
export default userApi;
