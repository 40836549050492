import React, { useContext } from "react";
import "../../css/header.css";
import NavigationLinks from "../NavigationLinks";
import { observer } from "mobx-react-lite";
import UserHeader from "./UserHeader";
import userStore from "../../store/userStore";
import SteamLogoutButton from "../Buttons/ActionButtons/SteamLogoutButton";

const Header = () => {
  return (
    <header>
      {userStore.isAuth && <UserHeader />}
      <NavigationLinks />
    </header>
  );
};

export default observer(Header);
