import React from "react";
import { attentionCardStatuses } from "../../../../utils/consts";
import OffersActiveCard from "./OffersActiveCard";

const OffersCards = (props) => {
  return (
    <>
      {props.status === attentionCardStatuses.active && (
        <OffersActiveCard {...props} />
      )}
    </>
  );
};

export default OffersCards;
