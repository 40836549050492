import axios from "axios";
//import { getHttpOnlyCookie, refreshTokens } from "./userApi";
import { showStandartNotification } from "../utils/helpers";
import { notificationTypes, notificationTimeouts } from "../utils/consts";

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

$authHost.interceptors.request.use((config) => {
  config.withCredentials = true;
  return config;
});

let retryCount = 0;
$authHost.interceptors.response.use(
  (config) => config,
  async (error) => {
    if (error.response.status === 401 && error.config && !retryCount) {
      const originalRequest = error.config;
      retryCount = 1;

      try {
        //await refreshTokens();
        return await $authHost.request(originalRequest);
      } catch (err) {
        showStandartNotification(
          err.message || "Для этой операции нужно зарегистрироваться",
          notificationTimeouts.short,
          notificationTypes.reject
        );
        throw err;
      }
    }

    throw error;
  }
);

export { $host, $authHost };
