import React, { useContext, useState } from "react";
import DinosaurCard, { MDinosaurCard } from "../DinosaurCard";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import { dinosaurSizes, dinosaurTypes } from "../../utils/consts";
import ActiveFilters from "./ActiveFilters";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import { AnimatePresence } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
import { megaShopStore } from "../../store/megaShopStore";

const Catalog = (props) => {
  const data = megaShopStore.visibleData || [];

  return (
    <div className="catalog">
      <SmallComponentHeading>Каталог</SmallComponentHeading>
      <ActiveFilters />
      <div className="catalog-content flex-container">
        <AnimatePresence mode="sync">
          {data.map((itemData, i) => (
            <MDinosaurCard
              key={itemData.id}
              data={itemData}
              size={dinosaurSizes.big}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              layout
            />
          ))}
        </AnimatePresence>
        {data.length === 0 && <p>Таких элементов нет</p>}
      </div>
    </div>
  );
};

export default observer(Catalog);
