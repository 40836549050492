import React, { useEffect, useState } from "react";
import { ReactComponent as LogoutSvg } from "../../../assets/svg/log_out.svg";
import "../../../css/logout-button.css";
import userApi from "../../../api/userApi";
import userStore from "../../../store/userStore";
import Button from "../../Button";
import { showModal } from "../../../helperComponents/ModalWrapper";

const ModalSure = (props) => {
  return (
    <div className="d-flex justify-center align-items-center direction-column gap-1">
      <p>{props.text || "Вы уверены?"}</p>
      <div className="buttons d-flex gap-1">
        <Button
          onClick={() => {
            props.onSure(true);
            props.onClose();
          }}
        >
          Да
        </Button>
        <Button
          className="background-dark border-dark"
          onClick={() => props.onClose()}
        >
          Назад
        </Button>
      </div>
    </div>
  );
};

const SteamLogoutButton = () => {
  const [isSure, setIsSure] = useState(false);
  const [modal, setModal] = useState(<></>);

  useEffect(() => {
    const handleLogout = async () => {
      try {
        const userInfo = await userApi.logout();
        userStore.setIsAuth(false);
        userStore.setData({});
        console.log(userInfo);
      } catch (err) {
        console.error(err);
      }
    };

    if (isSure) {
      handleLogout();
    }
  }, [isSure]);

  function handleLogoutClick() {
    const newModal = showModal({
      onClose: () => {
        setIsSure(false);
        setModal(<></>);
      },
      children: (
        <ModalSure
          onSure={() => setIsSure(true)}
          text={"Вы уверены что хотите выйти из аккаунта?"}
        />
      ),
    });
    setModal(newModal);
  }

  return (
    <>
      <button
        onClick={handleLogoutClick}
        className="small-text gradient-text logout"
      >
        <LogoutSvg />
        выйти
      </button>
      {modal}
    </>
  );
};

export default SteamLogoutButton;
