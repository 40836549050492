import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  contextValue,
  dinosaurTypes,
  megaShopTypes,
  notificationTimeouts,
  notificationTypes,
  valutes,
} from "../utils/consts";
import { ReactComponent as Leaflet } from "../assets/svg/leaflet.svg";
import { ReactComponent as Meat } from "../assets/svg/meat.svg";
import "../css/dinosaur-card.css";
import Button from "./Button";
import { showStandartNotification } from "../utils/helpers";
import ProductAbout from "./ProductAbout";
import ToBasket from "./ToBasket";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { showModal } from "../helperComponents/ModalWrapper";
import {
  catalogCategories,
  catalogCategoriesEng,
} from "../utils/megaShopItemsData";
/*

{
  type: dinosaurTypes,
  tier: number,
  image: img_src,
  text: string,
  teeth: string,
  oa: string,
  hint: string,
  size: string
}

*/

const DinosaurCard = forwardRef((props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [modal, setModal] = useState(<></>);

  const data = useMemo(() => props.data || {}, [props.data]);
  const type = catalogCategories[data.category];
  const tier = data.tier;

  const svgTypes = {
    [catalogCategoriesEng.herbivorous]: <Leaflet />,
    [catalogCategoriesEng.predator]: <Meat />,
  };

  const dinosaurTiers = {
    1: "first",
    2: "second",
    3: "third",
    4: "fourth",
  };

  useEffect(() => {
    if (isModalVisible) {
      const newModal = showModal({
        onClose: () => {
          console.log("closed");
          setIsModalVisible(false);
          setModal(<></>);
        },
        children: (
          <ProductAbout
            data={data}
            // onClose={() => onClose()}
            // onLink={(link, options = {}) => {
            //   console.log("second");
            //   onLink(link, options);
            // }}
          />
        ),
      });
      setModal(newModal);
    }
  }, [isModalVisible, data]);

  const imageLoaded = () => {
    setIsImageLoading(true);
  };

  return (
    <div
      ref={ref}
      {...props}
      className={`dinosaur-card ${props.size ? props.size : ""} ${
        dinosaurTiers[tier]
      } ${type}${data.oa || data.cost ? " buyable" : ""} ${
        props.className ? props.className : ""
      }`}
      data={null}
    >
      <div
        className={`card`}
        onClick={() => {
          if (!props.isNotClickable && (data.cost || data.oa)) {
            setIsModalVisible(true);
          }
        }}
      >
        {data.hint && (
          <div className="hint">
            <p>{data.hint}</p>
          </div>
        )}
        <div className={`card-background`}>
          <LazyLoadImage
            effect="opacity"
            onLoad={imageLoaded}
            src={data.image_url}
            alt={data.name_ru || "Товар"}
            draggable={props.isImageNotDraggable ? false : true}
          />
        </div>
        <div className="dinosaur-card-info">
          {tier && <p>{tier} ТИР</p>}
          {data.name_ru && (
            <p className={"name " + dinosaurTiers[tier]}>{data.name_ru}</p>
          )}
        </div>
        {svgTypes[type]}
        {data.amount > 1 && !data.isBuyable && (
          <div className="amount-absolute">x{data.amount}</div>
        )}
      </div>

      {data.text && <div className="text">{data.text}</div>}
      {(data.oa || data.cost) && <ToBasket data={data} />}

      {modal}
    </div>
  );
});

export default DinosaurCard;
export const MDinosaurCard = motion.create(DinosaurCard);
