import AdminPanel from "./pages/Admin/AdminPanel";
import RoulettePage from "./pages/Server/BattlePass/RoulettePage";
import Guilds from "./pages/Guilds/Guilds";
import GuildsCreate from "./pages/Guilds/GuildsCreate";
import GuildsMy from "./pages/Guilds/GuildsMy";
import GuildsSubs from "./pages/Guilds/GuildsSubs";
import Home from "./pages/Home";
import Ivents from "./pages/Ivents/Ivents";
import IventsAccepted from "./pages/Ivents/IventsDetails/IventsAccepted";
import IventsActive from "./pages/Ivents/IventsDetails/IventsActive";
import IventsDeclined from "./pages/Ivents/IventsDetails/IventsDeclined";
import IventsDetails from "./pages/Ivents/IventsDetails/IventsDetails";
import IventsOnModeration from "./pages/Ivents/IventsDetails/IventsOnModeration";
import MegaBank from "./pages/MegaProfile/MegaBank";
import MegaProfile from "./pages/MegaProfile/MegaProfile";
import MyDinosaurs from "./pages/MegaProfile/MyDinosaurs";
import MyStatistics from "./pages/MegaProfile/MyStatistics";
import MyStorage from "./pages/MegaProfile/MyStorage";
import MegaShop from "./pages/MegaShop";
import NotFound from "./pages/NotFound";
import Rules from "./pages/Rules/Rules";
import RulesEvrima from "./pages/Rules/RulesEvrima";
import Achievements from "./pages/Server/Achievements";
import Administration from "./pages/Server/Administration";
import BattlePass from "./pages/Server/BattlePass/BattlePass";
import FortuneWheel from "./pages/Server/BattlePass/FortuneWheel";
import DinosaursLeaderboard from "./pages/Server/Leaderboards/DinosaursLeaderboard";
import UsersLeaderboard from "./pages/Server/Leaderboards/UsersLeaderboard";
import OfferDetails from "./pages/Server/Offers/OfferDetails";
import Offers from "./pages/Server/Offers/Offers";
import TransitionPage from "./pages/TransitionPage";
import { independentPaths, paths } from "./paths";
import Basket from "./pages/Basket/Basket";
import BasketConfirm from "./pages/Basket/BasketConfirm";

export const routes = [
  {
    path: paths.Home,
    Component: Home,
  },

  {
    path: paths.MegaShop,
    Component: MegaShop,
  },

  // {
  //   path: paths.UsersLeaderboard,
  //   Component: UsersLeaderboard,
  // },
  // {
  //   path: paths.DinosaursLeaderboard,
  //   Component: DinosaursLeaderboard,
  // },

  // {
  //   path: paths.Achievements,
  //   Component: Achievements,
  // },

  // {
  //   path: paths.Administration,
  //   Component: Administration,
  // },

  // {
  //   path: paths.BattlePass,
  //   Component: BattlePass,
  // },
  // {
  //   path: paths.FortuneWheel,
  //   Component: FortuneWheel,
  // },
  // {
  //   path: paths.RoulettePage,
  //   Component: RoulettePage,
  // },

  // {
  //   path: paths.Offers,
  //   Component: Offers,
  // },
  // {
  //   path: paths.OfferDetails,
  //   Component: OfferDetails,
  // },

  // {
  //   path: paths.Guilds,
  //   Component: Guilds,
  // },
  // {
  //   path: paths.GuildsCreate,
  //   Component: GuildsCreate,
  // },
  // {
  //   path: paths.GuildsMy,
  //   Component: GuildsMy,
  // },
  // {
  //   path: paths.GuildsSubs,
  //   Component: GuildsSubs,
  // },

  {
    path: paths.MegaProfile,
    Component: MegaProfile,
  },
  // {
  //   path: paths.MyDinosaurs,
  //   Component: MyDinosaurs,
  // },
  // {
  //   path: paths.MyStatistics,
  //   Component: MyStatistics,
  // },
  // {
  //   path: paths.MyStorage,
  //   Component: MyStorage,
  // },
  {
    path: paths.MegaBank,
    Component: MegaBank,
  },

  // {
  //   path: paths.Ivents,
  //   Component: Ivents,
  // },
  // {
  //   path: paths.IventsDetails,
  //   Component: IventsDetails,
  // },
  // {
  //   path: paths.IventsAccepted,
  //   Component: IventsAccepted,
  // },
  // {
  //   path: paths.IventsActive,
  //   Component: IventsActive,
  // },
  // {
  //   path: paths.IventsDeclined,
  //   Component: IventsDeclined,
  // },
  // {
  //   path: paths.IventsOnModeration,
  //   Component: IventsOnModeration,
  // },
  // {
  //   path: paths.AdminPanel,
  //   Component: AdminPanel,
  // },

  {
    path: paths.Basket,
    Component: Basket,
  },
  {
    path: paths.BasketConfirm,
    Component: BasketConfirm,
  },
];

export const independentRoutes = [
  {
    path: independentPaths.TransitionPage,
    Component: TransitionPage,
  },
  {
    path: independentPaths.NotFound,
    Component: NotFound,
  },
  // {
  //   path: independentPaths.Rules,
  //   Component: Rules,
  // },
  // {
  //   path: independentPaths.RulesEvrima,
  //   Component: RulesEvrima,
  // },
];
