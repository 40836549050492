import React, { useEffect, useState } from "react";
import ComponentContainer from "../../components/ComponentContainer";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import MyLink from "../../components/MyLink";
import { paths } from "../../paths";
import basketStore from "../../store/basketStore";
import { v4 as uuidv4 } from "uuid";
import DinosaurCard from "../../components/DinosaurCard";
import {
  cookieNames,
  dinosaurGrowths,
  dinosaurSizes,
  dinosaurTypes,
  initialCookieOptions,
  megaShopTypes,
  notificationTimeouts,
  notificationTypes,
  valutes,
} from "../../utils/consts";
import { ReactComponent as TeethSvg } from "../../assets/svg/teeth.svg";
import { ReactComponent as OaSvg } from "../../assets/svg/oa.svg";
import {
  getGrammaticalEnding,
  showStandartNotification,
} from "../../utils/helpers";
import userStore from "../../store/userStore";
import AttentionCard from "../../components/Cards/AttentionCards/AttentionCard";
import Button from "../../components/Button";
import Changer from "../../templates/Changer";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { transaction } from "mobx";
import AddBalance from "../../templates/AddBalance";
import { useCookies } from "react-cookie";
import BasketItem from "../../components/Basket/BasketItem";
import BasketCheckedInfo from "../../components/Basket/BasketCheckedInfo";

const BasketConfirm = (props) => {
  const [choosedPaymentType, setChoosedPaymentType] = useState(valutes.teeth);

  const OaToTeeth = 5 / 1;
  const checkedItems = Object.entries(basketStore.items)
    .filter(([id]) => basketStore.checkedItemsIds.has(+id)) // Оставляем только те, у которых id есть в checkedItemsIds
    .map(([, item]) => item); // Преобразуем в массив объектов

  const totalPriceOa = basketStore.totalPriceOa;
  const totalPriceTeeth = basketStore.totalPriceTeeth;

  function isEnoughMoney() {
    if (choosedPaymentType === valutes.teeth) {
      return totalPriceTeeth <= userStore.userValutes.teeth;
    }
    if (choosedPaymentType === valutes.oa) {
      return totalPriceOa <= userStore.userValutes.oa;
    }
    return false;
  }

  return (
    <div className="page basket-page basket-confirm-page">
      <ComponentContainer className="">
        <p className="back-to-basket">
          <MyLink to={paths.Basket}>Вернуться в корзину</MyLink>
        </p>
        <SmallComponentHeading>Оформление заказа</SmallComponentHeading>
        <div className="about-columns flex-wrap">
          {checkedItems.length > 0 && (
            <>
              <div className="basket-items left-column">
                {!isEnoughMoney() && (
                  <div className="payment-types">
                    <div className="payment-types-container">
                      <div className="not-enough">
                        <p className="text-dangerous font-size-1-25">
                          Не хватает средств
                        </p>
                        {/* <p className="mb-1">Обменяйте вашу валюту</p>
                        <Changer
                          valuteFrom={
                            choosedPaymentType === valutes.teeth
                              ? valutes.oa
                              : valutes.teeth
                          }
                          valuteTo={choosedPaymentType}
                        />
                        <p>...или пополните баланс</p> */}
                        <AddBalance />
                      </div>
                    </div>
                  </div>
                )}
                <div className="info-list basket-items-container">
                  {checkedItems.map((item) => (
                    <BasketItem key={uuidv4()} item={item} isConfirm={true} />
                  ))}
                </div>
              </div>
              <BasketCheckedInfo
                isConfirm={true}
                isEnoughMoney={isEnoughMoney}
                choosedPaymentType={choosedPaymentType}
              />
            </>
          )}
          {checkedItems.length === 0 && (
            <>
              <div className="basket-empty">
                <div className="basket-empty-text">
                  <p className="text-center">Тут ничего нет :(</p>
                  <span>
                    Выберите хотя бы 1 товар в{" "}
                    <MyLink to={paths.Basket}>Корзине</MyLink> и нажмите
                    "Перейти к офорлению"
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </ComponentContainer>
    </div>
  );
};

export default observer(BasketConfirm);
