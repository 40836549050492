import React from "react";

const ComponentContainer = (props) => {
  return (
    <div className={`container`}>
      <div
        className={`container-body component-container${
          props.className ? " " + props.className : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ComponentContainer;
