import React from "react";
import { attentionCardStatuses } from "../../../../utils/consts";
import AdminPanelActiveCard from "./AdminPanelActiveCard";

const AdminPanelCards = (props) => {
  return (
    <>
      {props.status === attentionCardStatuses.active && (
        <AdminPanelActiveCard {...props} />
      )}
    </>
  );
};

export default AdminPanelCards;
