import React, { useState } from "react";
import ComponentContainer from "../../components/ComponentContainer";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import { ReactComponent as DoneSvg } from "../../assets/svg/done.svg";
import Button from "../../components/Button";
import { ReactComponent as RefreshSvg } from "../../assets/svg/refresh_02.svg";
import { ReactComponent as MultipleArrowsSvg } from "../../assets/svg/multiple-arrows.svg";
import { ReactComponent as WalletSvg } from "../../assets/svg/wallet.svg";
import { ReactComponent as TeethSvg } from "../../assets/svg/teeth.svg";
import { ReactComponent as OaSvg } from "../../assets/svg/oa.svg";
import MegaCommon from "../../components/MegaProfile/MegaCommon";
import "../../css/mega-bank.css";
import { usePageName } from "../../hooks/usePageName";
import { pageNames, valuteExchangeRates, valutes } from "../../utils/consts";
import Changer from "../../templates/Changer";
import AddBalance from "../../templates/AddBalance";

const MegaBank = () => {
  usePageName(pageNames.MegaProfile);

  return (
    <div className="mega-bank-page page mega-profile-page">
      <MegaCommon>
        <div className="mega-bank">
          <SmallComponentHeading>МегаБанк</SmallComponentHeading>
          {/* <div className="changer">
            <SmallComponentHeading>Обменник</SmallComponentHeading>
            <div className="changer-container">
              <Changer />
            </div>
          </div> */}
          <div className="exchange">
            <SmallComponentHeading>Курс обмена</SmallComponentHeading>
            <div className="exchange-container">
              <div className="exchange-item">
                <WalletSvg className="svg-text" width="16" height="16" /> 1 ₽{" "}
                <span className="gradient-text">=</span> <TeethSvg />{" "}
                {1 * valuteExchangeRates[valutes.rubles][valutes.teeth]} зуб.
              </div>
              <div className="exchange-item">
                <TeethSvg /> 1 зуб <span className="gradient-text">=</span>{" "}
                <OaSvg /> {1 * valuteExchangeRates[valutes.teeth][valutes.oa]}{" "}
                OA
              </div>
            </div>
          </div>
          <div className="balance">
            <SmallComponentHeading>Пополнение баланса</SmallComponentHeading>
            <AddBalance />
          </div>
        </div>
      </MegaCommon>
    </div>
  );
};

export default MegaBank;
