import React, { useEffect, useState, useMemo, useCallback } from "react";
import ComponentContainer from "../../components/ComponentContainer";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";

import Button from "../../components/Button";
import ToBasket from "../../components/ToBasket";
import DinosaurCard from "../../components/DinosaurCard";
import {
  dinosaurGrowths,
  dinosaurSizes,
  dinosaurTypes,
  initialCookieOptions,
  megaShopTypes,
} from "../../utils/consts";
import { observer } from "mobx-react-lite";
import MyLink from "../../components/MyLink";
import { getGrammaticalEnding } from "../../utils/helpers";
import { paths } from "../../paths";
import AttentionCard from "../../components/Cards/AttentionCards/AttentionCard";
import basketStore from "../../store/basketStore";
import { v4 as uuidv4 } from "uuid";
import "../../css/basket.css";
import { Icon } from "@iconify/react";
import { useCookies } from "react-cookie";
import { usePageName } from "../../hooks/usePageName";
import { useNavigate } from "react-router-dom";
import userStore from "../../store/userStore";
import BasketCheckedInfo from "../../components/Basket/BasketCheckedInfo";
import BasketItem from "../../components/Basket/BasketItem";
import { showModal } from "../../helperComponents/ModalWrapper";
import SteamAuthButton from "../../components/Buttons/ActionButtons/SteamAuthButton";

const Basket = () => {
  usePageName("Корзина");
  const [isAllChecked, setIsAllChecked] = useState(true);
  const [cookies, setCookie] = useCookies(["basket"]);

  const basketItemsArray = useMemo(
    () => Object.entries(basketStore.items),
    [basketStore.items]
  );

  useEffect(() => {
    setIsAllChecked(
      basketStore.checkedItemsIds.size === basketItemsArray.length
    );
  }, [basketStore.checkedItemsIds, basketItemsArray.length]);

  const toggleSelectAll = useCallback(() => {
    if (isAllChecked) {
      basketStore.clearCheckedItems();
    } else {
      basketStore.selectAllItems(basketItemsArray.map(([id]) => +id));
    }
    setIsAllChecked(!isAllChecked);
  }, [isAllChecked, basketItemsArray]);

  const confirmDeletion = useCallback(
    (itemsIds) => {
      if (itemsIds.size === 0) return;
      const { onClose } = showModal({
        children: (
          <>
            <SmallComponentHeading>
              Вы уверены что хотите удалить выбранные товары?
            </SmallComponentHeading>
            <p>
              Удалить {itemsIds.size}{" "}
              {getGrammaticalEnding(itemsIds.size, [
                "товар",
                "товара",
                "товаров",
              ])}
              ?
            </p>
            <Button
              className="expanded"
              onClick={() => {
                basketStore.removeItems(Array.from(itemsIds));
                setCookie("basket", basketStore.items, initialCookieOptions);
                onClose();
              }}
            >
              Да
            </Button>
          </>
        ),
      });
    },
    [setCookie]
  );

  return (
    <div className="page basket-page">
      <ComponentContainer className="about-columns flex-wrap">
        {userStore.isAuth ? (
          <>
            {basketItemsArray.length > 0 ? (
              <>
                <div className="basket-items left-column">
                  <SmallComponentHeading>
                    <div className="noted">
                      <span className="noted-parent">Корзина</span>
                      <div className="noted-data">
                        {basketItemsArray.length}
                      </div>
                    </div>
                  </SmallComponentHeading>

                  <div className="info-list basket-items-container">
                    <div className="basket-filter d-flex gap-1 mb-1">
                      <div className="choose-all">
                        <input
                          type="checkbox"
                          id="is-all-checked"
                          checked={isAllChecked}
                          onChange={toggleSelectAll}
                        />
                        <label htmlFor="is-all-checked">Выбрать всё</label>
                      </div>
                      <button
                        className="gradient-text dangerous"
                        onClick={() =>
                          confirmDeletion(basketStore.checkedItemsIds)
                        }
                      >
                        Удалить выбранные
                      </button>
                    </div>
                    {basketItemsArray.map(([id, item]) => (
                      <BasketItem
                        id={id}
                        key={uuidv4()}
                        item={item}
                        confirmDeletion={confirmDeletion}
                      />
                    ))}
                  </div>
                </div>
                <BasketCheckedInfo />
              </>
            ) : (
              <div className="basket-empty">
                <SmallComponentHeading>Корзина</SmallComponentHeading>
                <div className="basket-empty-text">
                  <p>В корзине нет товаров</p>
                  <span>
                    Выберите хотя бы 1 товар в{" "}
                    <MyLink to={paths.MegaShop}>МегаШопе</MyLink>
                  </span>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <p>Чтобы просмотреть вашу корзину пожалуйста войдите в профиль</p>
            <SteamAuthButton />
          </div>
        )}
      </ComponentContainer>
    </div>
  );
};

export default observer(Basket);
