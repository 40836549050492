import { makeAutoObservable } from "mobx";

class BasketStore {
  items = {}; // ключ - id, значение - объект с данными товара
  checkedItemsIds = new Set(); // содержит id выбранных товаров
  totalPriceOa = 0;
  totalPriceTeeth = 0;

  constructor() {
    makeAutoObservable(this);
  }

  // Установка всех товаров в корзину
  setItems(items) {
    this.items = items;
    this.updatePrices();
  }

  // Установка цен
  setTotalPriceOa(oa) {
    this.totalPriceOa = oa;
  }

  setTotalPriceTeeth(teeth) {
    this.totalPriceTeeth = teeth;
  }

  // Добавление товара в корзину
  addToBasket(data) {
    const newItems = { ...this.items };
    const existedItem = newItems[data.id];
    if (existedItem) {
      newItems[data.id].amount += 1;
    } else {
      newItems[data.id] = { ...data, amount: 1 };
    }
    this.setItems(newItems);
  }

  // Удаление одного экземпляра товара
  removeOneFromBasket(id) {
    const newItems = { ...this.items };
    const existedItem = newItems[id];
    if (existedItem) {
      if (existedItem.amount <= 1) {
        delete newItems[id];
      } else {
        newItems[id].amount -= 1;
      }
    }
    this.setItems(newItems);
    this.removeCheckedItemsIds([id]);
  }

  // Полное удаление товаров по массиву ID
  removeItems(ids) {
    const newItems = { ...this.items };
    ids.forEach((id) => delete newItems[id]);
    this.setItems(newItems);

    this.removeCheckedItemsIds(ids);
  }

  // Установка количества товара вручную
  setToBasket(data, amount = 1) {
    const newItems = { ...this.items };
    newItems[data.id] = { ...data, amount: Math.max(1, amount) };
    this.setItems(newItems);
  }

  // Выбор всех товаров
  selectAllItems(ids) {
    this.checkedItemsIds = new Set(ids);
    this.updatePrices();
  }

  // Снятие выбора со всех товаров
  clearCheckedItems() {
    this.checkedItemsIds = new Set();
    this.updatePrices();
  }

  // Тоггл выбора товара
  toggleCheckedItem(id) {
    const newCheckedItemsIds = new Set(this.checkedItemsIds);
    if (newCheckedItemsIds.has(id)) {
      newCheckedItemsIds.delete(id);
    } else {
      newCheckedItemsIds.add(id);
    }
    this.checkedItemsIds = newCheckedItemsIds;
    this.updatePrices();
  }

  removeCheckedItemsIds(ids) {
    const newCheckedItemsIds = new Set(this.checkedItemsIds);
    ids.forEach((id) => newCheckedItemsIds.delete(id));
    this.checkedItemsIds = newCheckedItemsIds;
    this.updatePrices();
  }

  // Обновление цен на основе выбранных товаров
  updatePrices() {
    const validCheckedItems = [...this.checkedItemsIds].filter(
      (id) => this.items[id]
    );

    this.totalPriceTeeth = validCheckedItems.reduce(
      (sum, id) =>
        sum + (this.items[id]?.teeth || 0) * (this.items[id]?.amount || 1),
      0
    );

    this.totalPriceOa = validCheckedItems.reduce(
      (sum, id) =>
        sum + (this.items[id]?.oa || 0) * (this.items[id]?.amount || 1),
      0
    );

    this.checkedItemsIds = new Set(validCheckedItems);
  }

  // Подготовка данных для подтверждения заказа
  prepareForCheckout() {
    return {
      checkedItems: [...this.checkedItemsIds].map((id) => this.items[id]),
      totalPriceTeeth: this.totalPriceTeeth,
      totalPriceOa: this.totalPriceOa,
    };
  }

  // Общее количество товаров в корзине
  get itemCount() {
    return Object.keys(this.items).length;
  }
}

const basketStore = new BasketStore();
export default basketStore;
