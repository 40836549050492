import { Link, useLocation } from "react-router-dom";
import userImg from "../../assets/img/avatar/Avatar_100.png";
import { ReactComponent as SteamSvg } from "../../assets/svg/steam.svg";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import A from "../A";
import { steamProfile, steamProfileUrl } from "../../utils/consts";
import { ReactComponent as ChangeSvg } from "../../assets/svg/refresh_02.svg";
import { ReactComponent as PlusSquareSvg } from "../../assets/svg/plus_square.svg";
import Button from "../Button";
import { ReactComponent as WalletSvg } from "../../assets/svg/wallet.svg";
import { ReactComponent as OaSvg } from "../../assets/svg/oa.svg";
import { ReactComponent as TeethSvg } from "../../assets/svg/teeth.svg";
import "../../css/user-menu.css";
import MiniHeading from "../Headings/MiniHeading";
import { useContext } from "react";
import { Context } from "../..";
import { paths } from "../../paths";
import MegaMenu from "./MegaMenu";
import GuildsMenu from "./GuildsMenu";
import IventsMenu from "./IventsMenu";
import ServerMenu from "./ServerMenu";
import AdminPanel from "../../pages/Admin/AdminPanel";
import AdminPanelMenu from "./AdminPanelMenu";
import OffersMenu from "./OffersMenu";
import MyLink from "../MyLink";
import userStore from "../../store/userStore";
import SteamAuthButton from "../Buttons/ActionButtons/SteamAuthButton";
import SteamLogoutButton from "../Buttons/ActionButtons/SteamLogoutButton";
import { observer } from "mobx-react-lite";

const UserMenu = (props) => {
  return (
    <div className="user-menu">
      <div className="user-info">
        {userStore.isAuth ? (
          <>
            <img
              effect="opacity"
              src={userStore.data.avatar}
              width={100}
              height={100}
              style={{ borderRadius: "50%" }}
              alt="Аватарка"
            />
            <div className="info">
              <div className="d-flex align-items-center justify-between">
                <span className="user-name">{userStore.data.username}</span>
                <SteamLogoutButton />
              </div>
              <div className="d-flex align-items-center justify-between">
                <A
                  href={steamProfileUrl + userStore.data.steam_id}
                  className="user-steam"
                >
                  <SteamSvg />
                  Steam
                </A>
                <span className="user-id gradient-text extra">
                  ID: <span>190</span>
                </span>
              </div>
            </div>
          </>
        ) : (
          <SteamAuthButton />
        )}
      </div>
      {props.mega && <MegaMenu />}
      {props.guilds && <GuildsMenu />}
      {props.ivents && <IventsMenu />}
      {props.server && <ServerMenu />}
      {props.adminPanel && <AdminPanelMenu />}
      {props.offers && <OffersMenu />}
    </div>
  );
};

export default observer(UserMenu);
