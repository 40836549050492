import React from "react";
import SmallComponentHeading from "../../../Headings/SmallComponentHeading";
import Button from "../../../Button";

const AdminPanelActiveCard = (props) => {
  return (
    <div className="attention-card active-card gradient-background extra-black">
      <div className="hint gradient-background leaf">{props.server}</div>
      <div className="content">
        <p>
          <b className="heading">{props.heading}</b>
        </p>
        <p className="font-size-0-75">
          <span>Ник: </span>
          <span className="gradient-text">{props.nickname}</span>
        </p>
        <p className="font-size-0-75">
          <span>Steam ID: </span>
          <span className="gradient-text">{props.steamId}</span>
        </p>
        <p className="font-size-0-75">
          <span>Дата тикета: </span>
          <span>{props.ticketDate}</span>
        </p>
        <Button className="spiked extended-half">ЗАЙТИ НА СЕРВЕР</Button>
      </div>
    </div>
  );
};

export default AdminPanelActiveCard;
