import React, { useContext } from "react";
import Button from "../../../Button";
import { paths } from "../../../../paths";
import { Link } from "react-router-dom";
import { Context } from "../../../..";
import MyLink from "../../../MyLink";

const OffersActiveCard = (props) => {
  return (
    <div className="attention-card active-card gradient-background extra-black">
      <div className="hint gradient-background leaf">{props.server}</div>
      <div className="content">
        <p>
          <b className="heading">{props.heading}</b>
        </p>
        <p className="font-size-0-75">
          <span>Автор: </span>
          <span>{props.author}</span>
        </p>
        <p className="font-size-0-75">
          <span>Добавлено: </span>
          <span>{props.date}</span>
        </p>
        <MyLink to={paths.OfferDetails}>
          <Button className="spiked extended-half">ПОДРОБНЕЕ</Button>
        </MyLink>
      </div>
    </div>
  );
};

export default OffersActiveCard;
