import React from "react";
import { attentionCardTypes } from "../../../utils/consts";
import AdminPanelCards from "./AdminPanel/AdminPanelCards";
import "../../../css/attention-cards.css";
import OffersCards from "./Offers/OffersCards";

const AttentionCard = (props) => {
  return (
    <>
      {props.type === attentionCardTypes.adminPanel && (
        <AdminPanelCards {...props} />
      )}
      {props.type === attentionCardTypes.offers && <OffersCards {...props} />}
    </>
  );
};

export default AttentionCard;
