import React, { useState } from "react";
import { notificationTypes } from "../../utils/consts";
import "../../css/standart-notification.css";

const StandartNotification = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const notificationType = notificationTypes[props.type];
  function closeNotification() {
    setIsVisible(false);
  }

  return (
    <>
      {isVisible && (
        <div
          className={`notification standart ${notificationType} ${
            props.className ? props.className : ""
          }`}
        >
          {props.text}
          <button
            className="close"
            onClick={props.onClose || closeNotification}
          />
        </div>
      )}
    </>
  );
};

export default StandartNotification;
