import { observer } from "mobx-react-lite";
import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import userStore from "../store/userStore";
import { motion } from "framer-motion";

const MyLink = forwardRef((props, ref) => {
  if (!userStore) {
    return <></>;
  }
  return (
    <Link
      ref={ref}
      {...props}
      to={!props.isDisabled ? userStore.choosedServerUrl + props.to : "#"}
    >
      {props.children}
    </Link>
  );
});

export default observer(MyLink);
export const MMYLink = motion.create(MyLink);
