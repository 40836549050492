import React from "react";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../..";
import { paths } from "../../paths";
import Button from "../Button";
import MyLink from "../MyLink";
import userStore from "../../store/userStore";

const OffersMenu = () => {
  const location = useLocation();
  const page = location.pathname;

  return (
    <>
      <div className="menu">
        <SmallComponentHeading>Статусы</SmallComponentHeading>
        <div className="buttons">
          <MyLink
            to={paths.IventsActive}
            className={
              page === userStore.choosedServerUrl + paths.IventsActive
                ? "active"
                : ""
            }
          >
            <Button className="extra no-border spiked">АКТИВНЫЕ</Button>
          </MyLink>
          <MyLink
            to={paths.IventsAccepted}
            className={
              page === userStore.choosedServerUrl + paths.IventsAccepted
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">ОДОБРЕННЫЕ</Button>
          </MyLink>
          <MyLink
            to={paths.IventsDeclined}
            className={
              page === userStore.choosedServerUrl + paths.IventsDeclined
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">ОТКЛОНЕННЫЕ</Button>
          </MyLink>
          <MyLink
            to={paths.IventsOnModeration}
            className={
              page === userStore.choosedServerUrl + paths.IventsOnModeration
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">НА МОДЕРАЦИИ</Button>
          </MyLink>
        </div>
      </div>
      <div className="menu">
        <SmallComponentHeading>Категории</SmallComponentHeading>
        <div className="buttons">
          <MyLink
            to={paths.IventsActive}
            className={
              page === userStore.choosedServerUrl + paths.IventsActive
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">ВСЕ</Button>
          </MyLink>
          <MyLink
            to={paths.IventsAccepted}
            className={
              page === userStore.choosedServerUrl + paths.IventsAccepted
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">СЕРВЕР</Button>
          </MyLink>
          <MyLink
            to={paths.IventsDeclined}
            className={
              page === userStore.choosedServerUrl + paths.IventsDeclined
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">DISCORD</Button>
          </MyLink>
          <MyLink
            to={paths.IventsOnModeration}
            className={
              page === userStore.choosedServerUrl + paths.IventsOnModeration
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">САЙТ</Button>
          </MyLink>
          <MyLink
            to={paths.IventsOnModeration}
            className={
              page === userStore.choosedServerUrl + paths.IventsOnModeration
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">&#183; Другое</Button>
          </MyLink>
        </div>
      </div>
    </>
  );
};

export default OffersMenu;
