import { $host } from "../../index";
import userStore from "../../../store/userStore";

export const generateSteamAuthUrl = async (discordCode = null) => {
  const returnUrl = userStore.choosedServerUrl;
  let url = `/auth/steam/generate-url?return_url=${returnUrl}`;
  if (discordCode) url += `&discord_code=${discordCode}`;

  console.log("url: ", url);

  const { data } = await $host.get(url);

  console.log("data: ", data);

  return data;
};

export const steamCallback = async (urlParams) => {
  try {
    const { data } = await $host.post("/auth/steam/callback", urlParams, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    console.error(err);
  }
};
