import React from "react";
import "../../css/glowing-button.css";

const GlowingButton = (props) => {
  return (
    <button {...props} className={"btn glowing " + props.className}>
      {props.children}
    </button>
  );
};

export default GlowingButton;
