import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import "../css/progress-bar.css";

const ProgressBar = forwardRef((props, ref) => {
  const max = +props.max || 100;
  const percent = props.value ? (+props.value / max) * 100 : 0;

  let additionClasses = [];
  if (props.manyColors) {
    if (percent < 40) {
      additionClasses.push("low");
    } else if (percent < 60) {
      additionClasses.push("medium");
    } else if (percent < 80) {
      additionClasses.push("high");
    } else {
      additionClasses.push("super-high");
    }
  } else {
    additionClasses.push("background-accent");
  }

  const progressBarAnimation = props.isAnimated
    ? {
        initial: { width: 0 },
        animate: { width: percent + "%" },
      }
    : {};

  return (
    <div
      ref={ref}
      className={`progress-bar ${props.className || ""}${
        percent === 100 ? "full" : ""
      }`}
    >
      {props.children}
      <motion.div
        className={`progress${additionClasses.map((item) => " " + item)}${
          !props.children ? " padding-0-25" : ""
        }`}
        style={{ width: percent + "%" }}
        {...progressBarAnimation}
      >
        {!props.children && !props.noContent && `${props.value}/${max}`}
      </motion.div>
    </div>
  );
});

export default ProgressBar;
export const MProgressBar = motion.create(ProgressBar);
