import React from "react";
import Button from "./Button";
import { Icon } from "@iconify/react";
import "../css/offcanvas.css";
import { toggleActive } from "../utils/helpers";

const Offcanvas = (props) => {
  return (
    <div
      {...props}
      className={`offcanvas active-handler ${
        props.className ? props.className : ""
      }`}
    >
      <div className="offcanvas-shade" onClick={(e) => toggleActive(e)} />
      <Button
        className="offcanvas-button no-background no-border"
        onClick={(e) => toggleActive(e)}
      >
        <Icon
          icon="iconamoon:menu-burger-horizontal-bold"
          width="1.2rem"
          height="1.2rem"
        />
      </Button>
      <div className="offcanvas-container">
        <div className="offcanvas-header">
          {props.head && <span className="offcanvas-title">{props.title}</span>}
          <button className="close" onClick={(e) => toggleActive(e)} />
        </div>
        <div className="offcanvas-body">{props.children}</div>
      </div>
    </div>
  );
};

export default Offcanvas;
