import React, { useContext } from "react";
import { dinosaurSizes, megaShopCharacteristics } from "../utils/consts";
import SmallComponentHeading from "./Headings/SmallComponentHeading";
import "../css/about-columns.css";
import "../css/characteristics.css";
import DinosaurCard from "./DinosaurCard";
import { paths } from "../paths";
import MyLink from "./MyLink";
import Button from "./Button";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "..";
import userStore from "../store/userStore";
import { observer } from "mobx-react-lite";

const ProductAbout = (props) => {
  const onLink = props.onLink || (() => {});
  console.log(onLink);
  const navigate = useNavigate();

  const data = props.data || {};

  return (
    <>
      <SmallComponentHeading>{data.name_ru}</SmallComponentHeading>
      <div className="about-columns">
        <div className="left-column">
          <div className="description-container">
            <SmallComponentHeading>Описание</SmallComponentHeading>
            {data.description && (
              <p className="description-text">{data.description}</p>
            )}
            {!data.description && (
              <p className="description-text">Описание отсутствует</p>
            )}
          </div>
          <div className="characteristics-container">
            <SmallComponentHeading>Характеристики</SmallComponentHeading>
            {Object.entries(data).length > 0 && (
              <table className="characteristics">
                <tbody>
                  {Object.entries(data).map((dataItem, i) => (
                    <>
                      {megaShopCharacteristics[dataItem[0]] && (
                        <tr className="characteristic">
                          <td>{megaShopCharacteristics[dataItem[0]]}</td>
                          <td>{dataItem[1]}</td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            )}
            {Object.entries(data).length === 0 && (
              <p className="description-text">Характеристики отсутствуют</p>
            )}
          </div>
        </div>
        <div className="right-column">
          <DinosaurCard
            data={data}
            size={dinosaurSizes.largest}
            isNotClickable={true}
          />
          {userStore.isAuth && (
            <>
              <MyLink to={paths.Basket}>
                <Button
                  onClick={() => {
                    console.log("first");
                    props.onLink(paths.Basket);
                  }}
                  className="abc"
                >
                  Перейти в корзину
                </Button>
              </MyLink>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(ProductAbout);
