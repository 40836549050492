import React from "react";
import "../css/button.css";

const Button = (props) => {
  return (
    <button {...props} className={"btn " + props.className}>
      {props.children}
    </button>
  );
};

export default Button;
