import { makeAutoObservable } from "mobx";
import { Cookies } from "react-cookie";
import { cookieNames, initialCookieOptions } from "../utils/consts";

const cookies = new Cookies();
class UserInventory {
  dinosaurs = {};
  busters = {};
  cases = {};
  eggs = {};

  constructor() {
    makeAutoObservable(this);
  }

  setDinosaurs(dinosaurs) {
    this.dinosaurs = dinosaurs;
  }

  addDinosaurs(dinosaurs) {
    const newDinosaurs = {
      ...this.dinosaurs,
      ...dinosaurs,
    };
    this.setDinosaurs(newDinosaurs);
  }

  removeDinosaurById(dinosaurId) {
    const newDinosaurs = Object.fromEntries(
      Object.entries(this.dinosaurs).filter(([key]) => key !== dinosaurId)
    );
    this.setDinosaurs(newDinosaurs);
  }

  setBusters(busters) {
    this.busters = busters;
  }

  setCases(cases) {
    this.cases = cases;
  }

  setEggs(eggs) {
    this.eggs = eggs;
  }
}

const userInventory = new UserInventory();

export default userInventory;
